import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
const initialState = {
  data: [],
  loading: false,
  error: null,
};

export const contentNicheDelete = createAsyncThunk(
  "resources/contentNicheDelete",
  async ({campaignId, contentNicheId}, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const token = state.otp?.data.token;

      const data = {
        content_niche_id: contentNicheId,
      };

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };

      const response = await axios.delete(
        `https://api.republicofinfluencers.in/v2/brands/campaign/content-niche-delete/${campaignId}`,
        config,
        data
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const contentNicheDeleteSlice = createSlice({
  name: "contentNicheDelete",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(contentNicheDelete.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(contentNicheDelete.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
        state.error = null;
      })
      .addCase(contentNicheDelete.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      });
  },
});

export default contentNicheDeleteSlice.reducer;