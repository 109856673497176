/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Layout } from "../../components";
import { useDispatch, useSelector } from "react-redux";
import { fetchInfluencersList } from "../../features/influencers/getInfluencerList";
import { useEffect } from "react";
import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Card,
  CardContent,
  Grid,
  Paper,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import DemoCard from "../home/components/DemoCard"
import BaseCard from "./components/BaseCard";
import { useState } from "react";
const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    borderRadius: "30px",
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

const Influencer = () => {
  const dispatch = useDispatch();
  const [showSkeleton, setShowSkeleton] = useState(true);

  const { data: Influencers, loading } = useSelector(
    (state) => state.influencersList.data
  );

  const [searchQuery, setSearchQuery] = useState("");

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  useEffect(() => {
    console.log("Fetching influencer list data....");
    dispatch(fetchInfluencersList());
  }, [dispatch]);

  useEffect(() => {
    
    if (Influencers) {
      setShowSkeleton(false);
    } else {
      setShowSkeleton(true);
    }
  }, [Influencers]);

  return (
    <>
      <Layout>
        <Paper sx={{ borderRadius: "20px" }} elevation={0}>
          <Stack>
            <CardContent
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                flexWrap: "wrap",
              }}
            >
              <Typography variant="h6">Influencers</Typography>
              <Box
                sx={{
                  maxWidth: "100%",
                  borderRadius: "20px",
                }}
                xs={12}
                sm={6}
                md={4}
                lg={3}
              >
                <Search>
                  <SearchIconWrapper>
                    <SearchIcon />
                  </SearchIconWrapper>
                  <StyledInputBase
                    placeholder="Search…"
                    inputProps={{ "aria-label": "search" }}
                    value={searchQuery}
                    onChange={handleSearchChange}
                  />
                </Search>
              </Box>
            </CardContent>
          </Stack>
        </Paper>
        <Box height={15} />
        <Grid container spacing={2}>
          {Influencers?.filter((influencer) =>
            influencer?.name?.toLowerCase().includes(searchQuery.toLowerCase())
          ).map((influencer) => (
            <Grid
              item
              key={influencer?.influencer_id}
              xs={12}
              sm={6}
              md={4}
              lg={4}
            >
              {showSkeleton ? (
                <Skeleton
                  animation="wave"
                  variant="rectangular"
                  style={{ height: "200px", borderRadius: "20px" }}
                />
              ) : (
                <DemoCard influencer={influencer} />
              )}
            </Grid>
          ))}
        </Grid>
      </Layout>
    </>
  );
};

export default Influencer;
