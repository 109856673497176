import React, { useEffect } from "react";
import { Layout } from "../../components";
import { useDispatch, useSelector } from "react-redux";
import { getFavourite } from "../../features/influencers/getFavourite";
// import FavCard from "./component/FavCard";
import { Box, CardContent, Grid, Paper, Stack, Typography } from "@mui/material";
import FavouriteCard from "./component/FavouriteCard";

const FavInfluencers = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getFavourite());
  }, [dispatch]);
  const { data: favInfluData } = useSelector(
    (state) => state.getFavInfluencer?.data
  );

  

  return (
    <>
      <Layout>
        <Paper sx={{borderRadius : "20px"}} elevation={0}>
        <Stack>
          <CardContent>
            <Typography variant="h6">Favourite Influencers</Typography>
          </CardContent>
        </Stack>
        </Paper>
        <Box height={15}/>
        <Grid container spacing={3}>
          {favInfluData?.map((influencer) => (
            <Grid item key={influencer.favourite_influencer_id} sm={4}>
              <FavouriteCard influencer={influencer}/>
            </Grid>
          ))}
        </Grid>
      </Layout>
    </>
  );
};

export default FavInfluencers;
