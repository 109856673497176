import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./redux/rootReducer";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const asyncMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    if (typeof action === "function") {
      return action(dispatch, getState);
    }
    return next(action);
  };

const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk, asyncMiddleware],
});

const persistor = persistStore(store);

export { store, persistor };
