/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useCallback } from "react";
import Checkbox from "@mui/material/Checkbox";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { fetchCampaignContent } from "../../../features/campaignContent/getCampaignContent"
import {
  Box,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Avatar,
  Stack,
  Button,
  Typography,
  Modal,
  Drawer,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  useMediaQuery,
  Card,
  CardContent,
  Grid,
  TextField,
  CardActions,
  DialogContentText,
} from "@mui/material";
import { broadcastComment } from "../../../features/comments/broadCast";
import { useParams, useNavigate } from "react-router-dom";
import { updateCampaignApplications } from "../../../features/campaignapplication/updateCampaignApplicationsSlice";
import { fetchCampaignApplications } from "../../../features/campaignapplication/campaignApplicationsSlice";
import ChatUI from "./ChatUi";
import CancelIcon from "@mui/icons-material/Cancel";
import { getComments } from "../../../features/comments/get";
import { addComment } from "../../../features/comments/add";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useTheme } from "@mui/material/styles";


const ApplicationCard = ({ onCardClick }) => {
  const dispatch = useDispatch();
  const { campaignId } = useParams();
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    const updatedOpenStates = [...openStates];
    updatedOpenStates.fill(false);
    setOpenStates(updatedOpenStates);
    setOpenDrawer(false);
  };
  const [selectedInfluencers, setSelectedInfluencers] = useState([
    {
      influencerId: null,
      campaignAppId: null,
    },
  ]);
  console.log(selectedInfluencers)
  const [broadcastMessage, setBroadcastMessage] = useState("");
  const [openStates, setOpenStates] = useState([]);
  const [openChat, setOpenChat] = useState(false);
  const navigate = useNavigate();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [userMessage, setUserMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const [selectedItemData, setSelectedItemData] = useState(null);

  const [showUndoButton, setShowUndoButton] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  
  const handleChat = ({ campaignAppId, influencerId,itemData }) => {
    handleClick("2");
    handleClose();
    setOpenDrawer(true);
    setOpenChat(true);
    setSelectedItemData(itemData);
    dispatch(getComments({ campaignAppId, influencerId }));
  };

  console.log(selectedItemData)

  const handleCloseDrawer = () => {
    setOpenDrawer(false);
  };

  const campaignApplications = useSelector(
    (state) => state.campaignApplications?.data?.data
  );
  // console.log(campaignApplications);

  const [selectedCategory, setSelectedCategory] = useState("All");
  const [filteredData, setFilteredData] = useState([]);

  const handleClick = useCallback(
    (category) => {
      setSelectedCategory(category);
      setSelectedInfluencers([]);

      if (category === "2") {
        dispatch(
          fetchCampaignApplications({
            campaignId,
            selectedCategory: "2",
          })
        );
      } else if (category === "3") {
        dispatch(
          fetchCampaignApplications({
            campaignId,
            selectedCategory: "3",
          })
        );
      } else {
        dispatch(
          fetchCampaignApplications({
            campaignId,
            selectedCategory: category === "All" ? "" : category,
          })
        );
      }
    },
    [dispatch, campaignId]
  );

  useEffect(() => {
    // console.log("FilteredData updated:", filteredData);
    if (filteredData) {
      const initialState = filteredData.map(() => false);
      setOpenStates(initialState);
    }
  }, [filteredData]);

  useEffect(() => {
    handleClick("1");
  }, [handleClick]);
  let undoTimer;

  const handleAcceptClick = (campaignAppliedId) => {
    dispatch(
      updateCampaignApplications({
        campaignId,
        campaignAppliedId,
        applicationStatusId: 2,
      })
    );

    setShowUndoButton(true);
    undoTimer = setTimeout(() => {
      setShowUndoButton(false);
    }, 200000);
    handleClose();
  };

  const handleRejectClick = (campaignAppliedId) => {
    dispatch(
      updateCampaignApplications({
        campaignId,
        campaignAppliedId,
        applicationStatusId: 3,
      })
    );
    handleClose();
  };

  useEffect(() => {
    if (
      selectedCategory === "All" ||
      selectedCategory === "2" ||
      selectedCategory === "3"
    ) {
      setFilteredData(campaignApplications);
    } else {
      setFilteredData(
        campaignApplications?.filter(
          (item) =>
            item.campaign_status?.campaign_status_id?.toString() ===
            selectedCategory
        )
      );
    }
  }, [campaignApplications, selectedCategory]);

  useEffect(() => {
    if (filteredData) {
      const initialState = filteredData.map(() => false);
      setOpenStates(initialState);
    }
  }, [filteredData]);
  const toggleModal = ({index,application}) => {
    setSelectedItemData(application)
    
    const updatedOpenStates = [...openStates];
    updatedOpenStates[index] = !updatedOpenStates[index];
    setOpenStates(updatedOpenStates);
    onCardClick(selectedItemData);
    
  };

  // console.log(filteredData);

  const handleSendMessage = ({ campaignAppId, influencerId }) => {
    if (!userMessage.trim()) return;

    if (!campaignAppId || !influencerId) {
      alert("Please select a campaign and influencer.");
      return;
    }

    const newUserMessage = {
      id: messages.length + 1,
      message: userMessage,
      sentByUser: true,
      timestamp: new Date().toLocaleTimeString(),
      noteType: 1,
    };

    dispatch(
      addComment({
        newUserMessage,
        campaignAppId,
        influencerId,
      })
    );

    setUserMessage("");
    handleClose();
  };

  const handleUndoClick = (campaignAppliedId) => {
    dispatch(
      updateCampaignApplications({
        campaignId,
        campaignAppliedId,
        applicationStatusId: 1,
      })
    );
    setShowUndoButton(false);
    clearTimeout(undoTimer);
  };
  const handleCheckboxChange = ({ influencerId, campaignAppId }) => {
    const isSelected = selectedInfluencers.some(
      (selectedInfluencer) => selectedInfluencer.influencerId === influencerId
    );
  
    if (isSelected) {
      setSelectedInfluencers((prevSelected) =>
        prevSelected.filter(
          (selectedInfluencer) => selectedInfluencer.influencerId !== influencerId
        )
      );
    } else {
      setSelectedInfluencers((prevSelected) => [
        ...prevSelected,
        { influencerId, campaignAppId },
      ]);
    }
  };

  const isAnyCheckboxSelected = selectedInfluencers.length > 0;
  const handleBroadcast = () => {
    setOpen(true);
  };

  const handlebrodcastClose = () => {
    dispatch(
      broadcastComment({
        inflenecrArr: selectedInfluencers,
        comment: broadcastMessage,
      })
    );
    setOpen(false);
    setBroadcastMessage("");
  };

  const contectCheckClick = (campaignAppId) => {
    dispatch(fetchCampaignContent(campaignAppId))
  }

  return (
    <>
      <FormControl>
        <FormLabel id="demo-row-radio-buttons-group-label">
          Application
        </FormLabel>
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          defaultValue="1"
        >
          {/* <FormControlLabel
            value="All"
            control={<Radio />}
            label="All category"
            onClick={() => handleClick("All")}
            sx={{
              display: "none",
            }}
          /> */}
          <FormControlLabel
            value="1"
            control={<Radio />}
            label="Applied"
            onClick={() => handleClick("1")}
          />
          <FormControlLabel
            value="2"
            control={<Radio />}
            label="Accepted"
            onClick={() => handleClick("2")}
          />
          <FormControlLabel
            value="3"
            control={<Radio />}
            label="Rejected"
            onClick={() => handleClick("3")}
          />
        </RadioGroup>
      </FormControl>
      <Box height={30} />
      <Stack direction="row" spacing={2}>
        {filteredData &&
          filteredData?.map((application, index) => (
            <div key={application?.influencer?.influencer_id}>
              <Card
                sx={{
                  padding: "7px",
                  borderRadius: "20px",
                }}
                // onClick={handleWholeClick}
              >
                <Box
                  onClick={() => toggleModal({index :index,application : application})}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    textAlign: "center",
                    p: 2,
                  }}
                >
                  <Avatar
                    sx={{ width: 70, height: 70, mb: 2 }}
                    src={application.avatar_url}
                  />

                  <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                    {application?.influencer?.name}
                  </Typography>
                </Box>
                <CardActions
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography>Select</Typography>
                    <Checkbox
                      icon={<CheckCircleOutlineIcon />}
                      checkedIcon={<CheckCircleIcon />}
                      onChange={() => {
                        handleCheckboxChange({
                         influencerId : application?.influencer?.influencer_id,
                         campaignAppId : application?.campaign_applied_id
                      });
                      }}
                    />
                  </Box>
                  {selectedItemData?.campaign_application_content_status && selectedItemData?.campaign_application_content_status !== null ? (
                    <Box>
                    <Button variant="text" onClick={() => contectCheckClick(
                      application?.campaign_applied_id
                    )}>{selectedItemData?.campaign_application_content_status === -1 ? "content check" : "Content Checked"}</Button>
                  </Box>
                   ) : "" }
                  
                </CardActions>
              </Card>

              <div>
                <Dialog
                  open={openStates[index]}
                  onClose={() => toggleModal({index : index , application : application})}
                  aria-labelledby="responsive-dialog-title"
                  aria-describedby="responsive-dialog-description"
                  fullScreen={fullScreen}
                  sx={{ padding: "7px" }}
                >
                  <DialogTitle
                    id="responsive-dialog-title"
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography variant="h5">Details</Typography>
                    {application?.application_status?.application_status_id ===
                    1 ? (
                      <Typography>Pending</Typography>
                    ) : (
                      <Typography>
                        {application?.application_status
                          ?.application_status_id === 2 ? (
                          <CheckCircleIcon
                            sx={{ fontSize: 20, color: "green" }}
                          />
                        ) : (
                          <CancelIcon sx={{ fontSize: 20, color: "red" }} />
                        )}
                      </Typography>
                    )}
                  </DialogTitle>
                  <DialogContent id="responsive-dialog-description">
                    <Stack flexDirection="column">
                      <Card elevation={0}>
                        <CardContent>
                          <Avatar
                            alt={application?.influencer?.name}
                            src={application?.influencer?.pan_card}
                          />
                          <Typography variant="h6" component="div">
                            {application?.influencer?.name}
                          </Typography>
                          <Typography variant="body2" color="textSecondary">
                            Followers: {application?.influencer?.email}
                          </Typography>
                        </CardContent>
                      </Card>
                    </Stack>
                    <Button
                      onClick={() =>
                        navigate(
                          `/influencer/${application?.influencer?.influencer_id}`
                        )
                      }
                    >
                      Seee Other Details
                    </Button>
                  </DialogContent>
                  <DialogActions flexDirection="column">
                    {application?.application_status?.application_status_id ===
                      2 ||
                    application?.application_status?.application_status_id ===
                      3 ? (
                      <Stack flexDirection="row"></Stack>
                    ) : (
                      <Stack flexDirection="row">
                        <TextField
                          label="Add a note"
                          InputProps={{
                            style: {
                              borderRadius: "10px",
                            },
                          }}
                          onChange={(e) => setUserMessage(e.target.value)}
                        />
                        <Button
                          variant="text"
                          onClick={() =>
                            handleSendMessage({
                              campaignAppId: application?.campaign_applied_id,
                              influencerId:
                                application?.influencer?.influencer_id,
                            })
                          }
                        >
                          Send
                        </Button>
                      </Stack>
                    )}
                    <Stack>
                      {application?.application_status
                        ?.application_status_id === 2 ||
                      application?.application_status?.application_status_id ===
                        3 ? (
                        <div>
                          {application?.application_status
                            ?.application_status_id === 2 ? (
                            <Stack
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "space-between",
                              }}
                            >
                              {showUndoButton === false && (
                                <Button
                                  variant="contained"
                                  marginX={2}
                                  sx={{
                                    borderRadius: "20px",
                                    // backgroundColor: "#8a76a2",
                                    backgroundColor:
                                      theme.palette.secondary.main,
                                    color: "#fff",
                                    marginLeft: "7px",
                                    textTransform: "none",
                                    "&:hover": {
                                      color: "#000",
                                      backgroundColor: "#fff",
                                      boxShadow: "none",
                                    },
                                  }}
                                  onClick={() =>
                                    handleChat({
                                      campaignAppId:
                                        application?.campaign_applied_id,
                                      influencerId:
                                        application?.influencer?.influencer_id,
                                      itemData: application,
                                    })
                                  }
                                >
                                  Chat
                                </Button>
                              )}
                              {showUndoButton && (
                                <Button
                                  variant="contained"
                                  marginX={2}
                                  onClick={() =>
                                    handleUndoClick(
                                      application?.campaign_applied_id
                                    )
                                  }
                                >
                                  Undo
                                </Button>
                              )}
                            </Stack>
                          ) : (
                            <Stack>
                              {showUndoButton && (
                                <Button
                                  variant="contained"
                                  marginX={2}
                                  onClick={() =>
                                    handleUndoClick(
                                      application?.campaign_applied_id
                                    )
                                  }
                                >
                                  Undo
                                </Button>
                              )}
                            </Stack>
                          )}
                        </div>
                      ) : (
                        <div>
                          <Button
                            variant="text"
                            onClick={() =>
                              handleAcceptClick(
                                application?.campaign_applied_id
                              )
                            }
                          >
                            Accept
                          </Button>
                          <Button
                            variant="text"
                            onClick={() =>
                              handleRejectClick(
                                application?.campaign_applied_id
                              )
                            }
                          >
                            Reject
                          </Button>
                        </div>
                      )}
                    </Stack>
                  </DialogActions>
                  <Stack></Stack>
                </Dialog>
              </div>
              <Box>
                <Dialog open={open} onClose={handlebrodcastClose}>
                  <DialogTitle>Send Broadcast Message</DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      send message to all the selected users
                    </DialogContentText>
                    <TextField
                      autoFocus
                      margin="dense"
                      id="message"
                      label="Message"
                      type="text"
                      fullWidth
                      variant="standard"
                      onChange={(e) => setBroadcastMessage(e.target.value)}
                    />
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handlebrodcastClose}>Cancel</Button>
                    <Button
                      onClick={() =>
                        handlebrodcastClose()
                      }
                    >
                      Send
                    </Button>
                  </DialogActions>
                </Dialog>
              </Box>
            </div>
          ))}
      </Stack>
      {isAnyCheckboxSelected && (
        <Box
          sx={{
            marginY: "10px",
            marginX: "10px",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button
            variant="contained"
            sx={{
              borderRadius: "20px",
              backgroundColor: theme.palette.primary.main,
              color: "#fff",
              textTransform: "none",
              "&:hover": {
                color: "#000",
                backgroundColor: "#fff",
                boxShadow: "none",
              },
            }}
            onClick={() => handleBroadcast()}
          >
            Send Message
          </Button>
        </Box>
      )}

      <Drawer
        anchor="right"
        open={openDrawer}
        onClose={handleCloseDrawer}
        PaperProps={{
          sx: {
            height: "calc(100% - 200px)",
            width: "40%",
            top: 100,
            bottom: 100,
            right: 25,
            borderRadius: "20px",
            marginLeft: "20px",
          },
        }}
      >
        {openChat === true ? (
          <ChatUI campaignData={selectedItemData} />
        ) : (
          <Typography>No One to Chat</Typography>
        )}
      </Drawer>
    </>
  );
};

export default ApplicationCard;
