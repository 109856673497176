import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';


export const verifyOtp = createAsyncThunk(
  'otpVerify/verifyOtp',
  async ({ otp, verificationCode }) => {
    const data = JSON.stringify({
      otp : otp,
      verification_code: verificationCode,
    });

    const config = {
      method: 'POST',
      maxBodyLength: Infinity,
      url: 'https://api.republicofinfluencers.in/v2/brands/auth/otp-verify',
      headers: {
        'Content-Type': 'application/json',
      },
      data: data,
    };

    try {
      const response = await axios.request(config);
      console.log(response.data)
      return response.data;
    } catch (error) {
      throw new Error(error.response ? error.response.data : 'An error occurred');
    }
  }
);

const otpVerifySlice = createSlice({
  name: 'otpVerify',
  initialState: {
    status: 'idle',
    error: null,
    data: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(verifyOtp.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(verifyOtp.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload; 
      })
      .addCase(verifyOtp.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default otpVerifySlice.reducer;
