import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  data: [],
  loading: false,
  error: null,
};

export const fetchCampaignContent = createAsyncThunk(
  "campaignContent/getCampaignContent",
  async (campaignAppId, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const token = state.otp.data.token;

      console.log("campaign app id",campaignAppId);

      const config = {
        method: "get",
        url: `https://api.republicofinfluencers.in/v2/brands/campaign_application_content/get-application-influencer-content/${campaignAppId}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      console.log(config)
      const response = await axios.request(config);
      console.log(response.data)
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const fetchCampaignContentSlice = createSlice({
  name: "fetchCampaignContent",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCampaignContent.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(fetchCampaignContent.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchCampaignContent.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default fetchCampaignContentSlice.reducer;
