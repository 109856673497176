import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios"; // Import Axios

const initialState = {
  data: [],
  loading: false,
  error: null,
};

export const fetchPlatform = createAsyncThunk(
  "resources/fetchPlatform",
  async (_, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const token = state.otp.data.token;
      const response = await axios.get(
        "https://api.republicofinfluencers.in/v2/resources/get-platform",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const getPlatformSlice = createSlice({
  name: "Platform ",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPlatform.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchPlatform.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchPlatform.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default getPlatformSlice.reducer;
