
import { createSlice } from '@reduxjs/toolkit';

const visibilitySlice = createSlice({
  name: 'visibility',
  initialState: {
    isButtonVisible: true,
  },
  reducers: {
    hideButton: (state) => {
      state.isButtonVisible = false;
    },
  },
});

export const { hideButton } = visibilitySlice.actions;

export default visibilitySlice.reducer;
