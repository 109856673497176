import { createSlice } from "@reduxjs/toolkit";

const logOutSlice = createSlice({
  name: "auth",
  initialState: {
    user: null,
    status: "idle",
    error: null,
    verificationCode: null,
  },
  reducers: {
    logout: (state) => {
      state.user = null;
      state.status = "idle";
      state.error = null;
      state.verificationCode = null;
    },
    clearState: (state) => {
      state.user = null;
      state.status = "idle";
      state.error = null;
      state.verificationCode = null;
    },
  },
});

export const { logout, clearState } = logOutSlice.actions;

export default logOutSlice.reducer;
