import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios"; 

const initialState = {
  data: [],
  loading: false,
  error: null,
};

export const fetchCampaignGoal = createAsyncThunk(
  "resources/fetchCampaignGoal",
  async (_, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const token = state.otp.data.token;

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axios.get(
        `https://api.republicofinfluencers.in/v2/resources/get-campaign-goal`,
        config
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const getCampaignGoalSlice = createSlice({
  name: "Campaign Goal",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCampaignGoal.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCampaignGoal.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchCampaignGoal.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default getCampaignGoalSlice.reducer;
