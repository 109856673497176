import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  data: [],
  loading: false,
  error: null,
};

export const platformDelete = createAsyncThunk(
  "resources/platformDelete",
  async ({ campaignId, platformArr }, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const token = state.otp?.data?.token;
      console.log(token);
      console.log(campaignId, platformArr);

      let data = JSON.stringify({
        platform_id: platformArr,
      });

      let config = {
        method: "delete",
        url: `https://api.republicofinfluencers.in/v2/brands/campaign/platform-delete/${campaignId}`,
        headers: {
          authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: data,
      };

      const response = await axios.request(config);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const platformDeleteSlice = createSlice({
  name: "platformDelete",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(platformDelete.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(platformDelete.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
        state.error = null;
      })
      .addCase(platformDelete.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      });
  },
});

export default platformDeleteSlice.reducer;
