import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Card,
  CardMedia,
  Typography,
  CardContent,
  Chip,
  Skeleton,
} from "@mui/material";

const useStyles = makeStyles(() => ({
  card: {
    position: "relative",
    overflow: "hidden",
    transition: "transform 0.2s",
    "&:hover": {
      transform: "scale(1.05)",
    },
    borderRadius: "20px",
  },
  cardContent: {
    position: "absolute",
    bottom: 0,
    left: 0,
    width: "100%",
    backgroundColor: "rgba(255, 255, 255, 0.8)",
    padding: "8px 16px",
    transform: "translateY(100%)",
    opacity: 0,
    transition: "transform 0.2s, opacity 0.2s",
  },
  showCardContent: {
    transform: "translateY(0)",
    opacity: 1,
  },
}));

const BaseCard = ({ influencer }) => {
  const classes = useStyles();
  const [isHovered, setIsHovered] = useState(false);
  const navigate = useNavigate();
  const [showSkeleton, setShowSkeleton] = useState(true);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };
  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  const niche = influencer.influencer_content_niches?.map(
    (el) => el.content_niche?.content_niche_name
  );
  useEffect(() => {
    if (influencer) {
      setShowSkeleton(false);
    } else {
      setShowSkeleton(true);
    }
  }, [influencer]);

  return (
    <div>
      {showSkeleton ? (
        <Skeleton
          animation="wave"
          variant="rectangular"
          style={{ height: "200px", borderRadius: "20px" }}
        />
      ) : (
        <Card
          className={classes.card}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onClick={() => navigate(`/influencer/${influencer.influencer_id}`)}
        >
          <CardMedia
            component="img"
            image="https://images.unsplash.com/photo-1629114695419-9cd72ac2e32c?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1469&q=80"
            sx={{ borderRadius: "20px" }}
          />
          <CardContent
            className={`${classes.cardContent} ${
              isHovered ? classes.showCardContent : ""
            }`}
          >
            <Typography variant="h6">{influencer?.name}</Typography>
            <Typography variant="body2">{influencer.bio}</Typography>
            <Box>
              {niche?.map((ele, i) => (
                <Chip
                  key={i}
                  label={ele}
                  variant="h4"
                  sx={{ marginRight: "5px" }}
                />
              ))}
            </Box>
          </CardContent>
        </Card>
      )}
    </div>
  );
};

export default BaseCard;
