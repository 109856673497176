import React, { useEffect, useState, useRef, useCallback } from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Fab from "@mui/material/Fab";
import { useNavigate } from "react-router-dom";
import {
  Box,
  IconButton,
  Stack,
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  Typography,
  Avatar,
  Skeleton,
} from "@mui/material";
import FavoriteIcon from "@mui/icons-material/Favorite";
import SendIcon from "@mui/icons-material/Send";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getComments } from "../../../features/comments/get";
import { addComment } from "../../../features/comments/add";
import { deleteComment } from "../../../features/comments/deleteComments";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { fetchCampaignApplications } from "../../../features/campaignapplication/campaignApplicationsSlice";
import { fetchInfluencerDetails } from "../../../features/influencers/getInfluencerDetails";

const chatSectionStyle = {
  width: "100%",
  height: "50vh",
  padding: 2,
};

const messageAreaStyle = {
  height: "40vh",
  overflowY: "auto",
  display: "flex",
  flexDirection: "column-reverse",
};

const sendButtonStyle = {
  marginLeft: "30px",
};

const ChatUI = ({ campaignData }) => {
  const messageAreaRef = useRef();
  // console.log(campaignData)

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [userMessage, setUserMessage] = useState("");
  const { campaignId } = useParams();
  const [influencerId, setInfluencerId] = useState(null);
  const [imagePreviewOpen, setImagePreviewOpen] = useState(false);
  const [selectedCommentId, setSelectedCommentId] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const getCommentsForInfluencer = useCallback(
    (influencerId, campaignAppId) => {
      dispatch(getComments({ campaignAppId, influencerId }));
    },
    [dispatch]
  );
  const { data: getComment } = useSelector((state) => state.getComments?.data);
  const handleDeleteMessage = ({commentId}) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this message?");

    if (confirmDelete) {
      dispatch(deleteComment({ commentId }));
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(fetchCampaignApplications({ campaignId, category: 1 }));
      
      const newInfluencerId = getComment?.[0]?.influencer_id;
      setInfluencerId(newInfluencerId);
      
      await dispatch(fetchInfluencerDetails(newInfluencerId));
    };
  
    fetchData();
  }, [dispatch, campaignId, getComment,campaignData]);
  

  useEffect(() => {
    // Refresh getComments API every 3 seconds
    const intervalId = setInterval(() => {
      if (campaignData?.influencer?.influencer_id && campaignData?.campaign_applied_id) {
        getCommentsForInfluencer(
          campaignData?.influencer?.influencer_id,
          campaignData?.campaign_applied_id
        );
      }
    }, 2000);

    return () => clearInterval(intervalId);
  }, [influencerId, getComment, getCommentsForInfluencer,campaignData]);

  const { data: selectedInfluencer } = useSelector(
    (state) => state.influencerDetails?.data
  );
  const handleSendMessage = () => {
    if (!userMessage.trim()) return;

    const campaignAppId = campaignData?.campaign_applied_id;
    const influencerId = campaignData?.influencer?.influencer_id;

    if (!campaignAppId || !influencerId) {
      alert("Please select a campaign and influencer.");
      return;
    }

    const newUserMessage = {
      id: getComment?.length + 1,
      message: userMessage,
      sentByUser: true,
      timestamp: new Date().toLocaleTimeString(),
      noteType: 0,
    };

    dispatch(
      addComment({
        newUserMessage,
        campaignAppId,
        influencerId,
      })
    );

    setUserMessage("");
    getCommentsForInfluencer(influencerId, campaignAppId);
  };

  const convertImageToBase64 = (imageFile) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsDataURL(imageFile);
    });
  };

  const convertPdfToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => {
        resolve(reader.result);
      };

      reader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const openImagePreview = (base64Image, commentId) => {
    setSelectedImage(base64Image);
    setSelectedCommentId(commentId);
    setImagePreviewOpen(true);
  };

  const closeImagePreview = () => {
    setSelectedImage(null);
    setSelectedCommentId(null);
    setImagePreviewOpen(false);
  };
  const handleDeleteImage = () => {
    if (selectedCommentId) {
      handleDeleteMessage({ commentId: selectedCommentId });
      closeImagePreview();
    }
  };

  const handleFileUpload = async (e) => {
    const selectedFile = e.target.files[0];

    if (!selectedFile) return;

    if (selectedFile.type.startsWith("image")) {
      const base64 = await convertImageToBase64(selectedFile);

      const newUserMessage = {
        id: getComment?.length + 1,
        message: "",
        sentByUser: true,
        timestamp: new Date().toLocaleTimeString(),
        file: base64.split(",")[1],
      };

      const campaignAppId = campaignData?.campaign_applied_id;
      const influencerId = campaignData?.influencer?.influencer_id;
      const fileType = "image";

      dispatch(
        addComment({
          newUserMessage,
          campaignAppId,
          influencerId,
          fileType,
        })
      );
    } else if (selectedFile.type === "application/pdf") {
      const base64 = await convertPdfToBase64(selectedFile);

      const newUserMessage = {
        id: getComment?.length + 1,
        message: "",
        sentByUser: true,
        timestamp: new Date().toLocaleTimeString(),
        file: base64.split(",")[1],
      };

      const campaignAppId = campaignData?.campaign_applied_id;
      const influencerId = campaignData?.influencer?.influencer_id;
      const fileType = "pdf";

      dispatch(
        addComment({
          newUserMessage,
          campaignAppId,
          influencerId,
          fileType,
        })
      );
    } else {
      alert("File type not supported.");
    }
  };
  return (
    <div>
      <Grid container sx={chatSectionStyle}>
        <Grid item xs={12} sm={12} >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            p={2}
          >
            <Avatar src="" />
            <Typography variant="h6" sx={{ marginLeft: 2 }}>
              {selectedInfluencer?.name}
            </Typography>
            {selectedInfluencer?.favourite_influencers &&
            selectedInfluencer?.favourite_influencers[0]
              ?.favourite_influencer_flag === 1 ? (
              <Button
                type="text"
                onClick={() =>
                  navigate(`/influencer/${selectedInfluencer?.influencer_id}`)
                }
              >
                Make Favorite
              </Button>
            ) : (
              <FavoriteIcon sx={{ color: "red" }} />
            )}
          </Box>
        </Grid>

        <Grid item xs={12} sm={12}>
          {getComment && getComment.length > 0 ? (
            <List sx={messageAreaStyle} ref={messageAreaRef}>
              {getComment?.map((message) => (
                <ListItem
                  key={message?.comment_id}
                  sx={{
                    justifyContent:
                      message?.sender_type === "BRAND"
                        ? "flex-end"
                        : "flex-start",
                    flexDirection:
                      message?.sender_type === "BRAND" ? "row-reverse" : "row",
                  }}
                >
                  <Grid container>
                    <Grid item xs={12} sm={12}>
                      {message.comment_file && message.file_type === "image" ? (
                        <img
                          src={message.comment_file}
                          align={
                            message?.sender_type === "BRAND" ? "right" : "left"
                          }
                          alt="messageImage"
                          onClick={() =>
                      openImagePreview(message.comment_file, message.comment_id)
                    }
                          style={{
                            cursor: "pointer",
                            height: "100px",
                            // width: "100px",
                            background:
                              message?.sender_type === "BRAND"
                                ? "#C8E6C9"
                                : "#BBDEFB",
                            borderRadius: "20px",
                            padding: "10px",
                            maxWidth: "200px",
                          }}
                        />
                      ) : (
                        <div
                          onClick={() =>
                            handleDeleteMessage({
                              commentId: message?.comment_id,
                            })
                          }
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",

                            background:
                              message?.sender_type === "BRAND"
                                ? "#C8E6C9"
                                : "#BBDEFB",
                            borderRadius: "20px",
                            padding: "10px",
                            maxWidth: "200px",
                            marginLeft:
                              message?.sender_type === "BRAND" ? "auto" : 0,
                            marginRight:
                              message?.sender_type === "BRAND" ? 0 : "auto",
                          }}
                        >
                          {message?.comment_text}
                        </div>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <ListItemText
                        secondary={new Date(message.createdAt).toLocaleString(
                          "en-US",
                          {
                            year: "numeric",
                            month: "long",
                            day: "numeric",
                            hour: "numeric",
                            minute: "numeric",
                            second: "numeric",
                            hour12: true,
                          }
                        )}
                        align={
                          message?.sender_type === "BRAND" ? "right" : "left"
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      {/* Add the delete button */}
                    </Grid>
                  </Grid>
                </ListItem>
              ))}
            </List>
          ) : (
            <Skeleton
              sx={{
                height: "100%",
              }}
            />
          )}
          <Stack
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              flexDirection: "row",
              position: "sticky",
              bottom: 0,
              backgroundColor: "white",
              padding: "10px",
            }}
          >
            <Stack
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              flexDirection="row"
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <TextField
                  id="outlined-basic-email"
                  label="Type Something"
                  variant="outlined"

                  value={userMessage}
                  onChange={(e) => setUserMessage(e.target.value)}
                  InputProps={{
                    style: {
                      borderRadius: "20px",
                    },
                  }}
                />
                <label htmlFor="fileInput">
                  <input
                    type="file"
                    accept=".jpg, .jpeg, .png, .pdf"
                    style={{ display: "none" }}
                    id="fileInput"
                    onChange={handleFileUpload}
                  />
                  <IconButton
                    color="primary"
                    component="span"
                    sx={sendButtonStyle}
                  >
                    <AttachFileIcon />
                  </IconButton>
                </label>
              </div>
            </Stack>
            <Stack>
              <Fab
                color="primary"
                aria-label="add"
                sx={sendButtonStyle}
                onClick={handleSendMessage}
              >
                <SendIcon />
              </Fab>
            </Stack>
          </Stack>
        </Grid>
      </Grid>

      <Dialog
        open={imagePreviewOpen}
        onClose={closeImagePreview}
        style={{ borderRadius: "20px" }}
      >
        <DialogTitle>Image Preview</DialogTitle>

        <DialogContent>
          <img
            src={selectedImage}
            alt="selectedImage"
            style={{
              width: "100%",
              height: "auto",
              maxHeight: "100%",
              borderRadius: "20px",
            }}
          />
        </DialogContent>

        <Button onClick={handleDeleteImage} color="primary">
          Delete Image
        </Button>
        <Button onClick={closeImagePreview} color="primary">
          Close
        </Button>
      </Dialog>
    </div>
  );
};

export default ChatUI;
