import React, { useEffect, useState } from "react";
import { Layout } from "../../components";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { fetchProfile } from "../../features/brandprofile/getBrandProfile";
import { fetchDemoInfluencers } from "../../features/influencers/demoInfulencers";
import DemoCard from "./components/DemoCard";
import {
  Grid,
  Typography,
  Button,
  Box,
  CardContent,
  Stack,
  Paper,
} from "@mui/material";
import bannerImage from "../../images/complete_profile_banner.svg";

const Home = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [isFetchingMore, setIsFetchingMore] = useState(false);
  const [itemsToDisplay, setItemsToDisplay] = useState(5);

  useEffect(() => {
    dispatch(fetchProfile());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchDemoInfluencers(page));
  }, [dispatch, page]);

  const completeProfileClick = () => {
    navigate("/complete-profile");
  };

  const brandProfileStatus = useSelector((state) =>
    state.brandprofileget?.data.data?.profile_status === 1 ? true : false
  );
  console.log(brandProfileStatus)
  const profileData = useSelector((state) => state.brandprofileget?.data?.data);
  const demoData = useSelector((state) => state.demoInfluencers?.data?.data);

  console.log(demoData);
  useEffect(() => {
    const onScroll = () => {
      const scrolledToBottom =
        window.innerHeight + window.scrollY >= document.body.offsetHeight;

      if (
        scrolledToBottom &&
        !isFetchingMore &&
        itemsToDisplay < demoData?.length
      ) {
        console.log("Fetching more data...");
        setItemsToDisplay(itemsToDisplay + 5);
        setIsFetchingMore(true);
      }
    };

    document.addEventListener("scroll", onScroll);

    return function () {
      document.removeEventListener("scroll", onScroll);
    };
  }, [isFetchingMore, itemsToDisplay, demoData]);

  return (
    <Layout>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Box
            sx={{
              backgroundImage: `url(${bannerImage})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              height: "40vh",
              display: "flex",
              alignItems: "center",
              borderRadius: "20px",
            }}
          >
            <Box
              sx={{
                textAlign: "start",
                marginLeft: "20px",
                marginTop: "80px",
              }}
            >
              {brandProfileStatus ? (
                <Typography variant="h3">Welcome to ROI</Typography>
              ) : (
                <Typography variant="h3">Update Your Profile</Typography>
              )}

              <Typography
                variant="h6"
                sx={{
                  color: "#5b5858",
                  mb: 2,
                }}
              >
                Unite Your Influence with Elevate Your Impact and Style!
              </Typography>
              {brandProfileStatus ? (
                <Typography variant="h4">{profileData?.name}</Typography>
              ) : (
                <Button
                  variant="contained"
                  onClick={completeProfileClick}
                  sx={{
                    borderRadius: "30px",
                    backgroundColor: "#000",
                    color: "#fff",
                    textTransform: "none",
                    "&:hover": {
                      backgroundColor: "#252525",
                    },
                  }}
                >
                  Update Profile
                </Button>
              )}
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Paper
        sx={{ borderRadius: "15px", marginTop: "10px", boxShadow: "none" }}
      >
        <Stack>
          <CardContent
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              flexWrap: "wrap",
            }}
          >
            <Box>
              <Typography variant="h4">Influencers</Typography>
              <Typography variant="subtitle1">
                Lorem ipsum dolor sit amet{" "}
              </Typography>
            </Box>
            <Box
              sx={{
                maxWidth: "100%",
                borderRadius: "20px",
              }}
              xs={12}
              sm={6}
              md={4}
              lg={3}
            >
              
              <Button
                variant="contained"
                sx={{
                  borderRadius: "20px",
                  backgroundColor: "#000",
                  color: "#fff",
                  textTransform: "none",
                  padding: "8px 25px",
                  "&:hover": {
                    backgroundColor: "#fff",
                    color: "#000",
                  },
                }}
                onClick={()=>{(brandProfileStatus === true) ? navigate("/influencer") : navigate('/complete-profile') } }
              >
                Influencers
              </Button>
            </Box>
          </CardContent>
        </Stack>
      </Paper>
      <Box height={15} />

      <Grid container spacing={3} justifyContent="center">
        {demoData?.slice(0, itemsToDisplay).map((demoInfluencer) => (
          <Grid
            item
            key={demoInfluencer.influencer_id}
            xs={12}
            sm={6}
            md={4}
            lg={4}
            xl={4}
          >
            <DemoCard influencer={demoInfluencer} />
          </Grid>
        ))}
      </Grid>
    </Layout>
  );
};

export default Home;
