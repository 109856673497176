/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-script-url */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchCampaign } from "../../features/campaign/getCampagin";
import { Layout } from "../../components";
import CampCard from "./components/CampCard";
import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Card,
  CardContent,
  Grid,
  Paper,
  Stack,
  Typography,
  TextField,
} from "@mui/material";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));
const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    borderRadius: "30px",
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

const MyCampaign = () => {
  const dispatch = useDispatch();
  const [searchQuery, setSearchQuery] = useState("");

  const campaignData = useSelector((state) => state.campaign.data?.data);

  useEffect(() => {
    dispatch(fetchCampaign());
  }, [dispatch]);

  if (!campaignData) {
    return null;
  }
  console.log(campaignData);

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate();
    const monthIndex = date.getMonth();
    const monthName = monthNames[monthIndex];
    const year = date.getFullYear();
    return `${day}th, ${monthName} ${year}`;
  }

  const essencialCampaignData = campaignData.map((campaign) => ({
    id: campaign?.campaign_id,
    title: campaign?.campaign_name,
    location: campaign?.location,
    about: campaign?.campaign_about,
    status: campaign?.campaign_status,
    budget: campaign?.campaign_budget,
    image: campaign?.campaign_images,
    platform: campaign?.campaign_platforms.map(
      (plat) => plat.platform?.platform_img
    ),
    formattedStartDate: formatDate(campaign?.campaign_start_dt),
  }));

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  return (
    <>
      <Layout>
        <Paper sx={{ borderRadius: "20px" }} elevation={0}>
          <Stack>
            <CardContent  sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                flexWrap : "wrap",

              }}
               >
              <Typography variant="h6">My Campaigns</Typography>
              <Box
                sx={{
                  maxWidth: "100%",
                }}
                xs={12}
                sm={6}
                md={4}
                lg={3}
              >
                <Search>
                  <SearchIconWrapper>
                    <SearchIcon />
                  </SearchIconWrapper>
                  <StyledInputBase
                    placeholder="Search…"
                    inputProps={{ "aria-label": "search" }}
                    value={searchQuery}
                    onChange={handleSearchChange}
                  />
                </Search>
              </Box>
            </CardContent>
          </Stack>
        </Paper>
        <Box height={15} />
        {essencialCampaignData.length === 0 ? (
          <Grid
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              src="https://indususedcars.com/assets/theme/images/no_result_found.png"
              alt="Nothing"
            />
          </Grid>
        ) : (
          <Grid container spacing={2}>
            {essencialCampaignData
              ?.filter((campaign) =>
                campaign?.title
                  .toLowerCase()
                  .includes(searchQuery.toLocaleLowerCase())
              )
              .map((campaign) => (
                <Grid item key={campaign.id} xs={12} sm={6} md={4} lg={4} xl={3} >
                  <CampCard campaign={campaign} />
                </Grid>
              ))}
          </Grid>
        )}
        
      </Layout>
    </>
  );
};

export default MyCampaign;
