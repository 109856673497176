import React from "react";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/styles";
import {
  Avatar,
  Box,
  CardActions,
  CardHeader,
  Tooltip,
  Button,
} from "@mui/material";
import logo from "../../../images/profileimage.jpg";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import MaleOutlinedIcon from "@mui/icons-material/MaleOutlined";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import facebookImage from "../../../images/png-transparent-fb-facebook-facebook-logo-social-media-icon.png";
import instagramImage from "../../../images/pngtree-three-dimensional-instagram-icon-png-image_6618437.png";
import youtubeImage from "../../../images/6-8.jpg";
import Fade from "@mui/material/Fade";

const DemoCard = ({ influencer }) => {
  const navigate = useNavigate();
  const theme = useTheme();

  console.log(influencer);
  const niche = influencer.influencer_content_niches?.map(
    (el) => el.content_niche?.content_niche_name
  );
  const formatDate = (dateStr) => {
    const options = { year: "numeric", month: "long", day: "2-digit" };
    const date = new Date(dateStr);
    return date.toLocaleDateString(undefined, options);
  };
  const truncateText = (text, maxLength) => {
    if (text && text.length > maxLength) {
      return text.slice(0, maxLength) + " ...";
    }
    return text;
  };

  const firstThreeNiche = niche?.slice(0, 3);
  const remainingNiche = niche?.slice(3);

  return (
    <>
      <Card
        variant="outlined"
        sx={{
          borderRadius: "15px",
          backgroundColor: "rgb(243,243,243)",
          padding: "30px",
          border: "none",
        }}
      >
        <CardHeader
          title={influencer?.name}
          subheader={
            <Box>
              <Chip
                icon={
                  <LocationOnOutlinedIcon
                    sx={{
                      fontSize: "16px",
                    }}
                  />
                }
                sx={{
                  backgroundColor: "#fff",
                  marginRight: "7px",
                }}
                label={
                  <span
                    sx={{
                      fontSize: "16px",
                    }}
                  >
                    Kolkata
                  </span>
                }
              />
              <Chip
                icon={
                  <MaleOutlinedIcon
                    sx={{
                      fontSize: "16px",
                    }}
                  />
                }
                label={
                  <span
                    sx={{
                      fontSize: "16px",
                    }}
                  >
                    {influencer?.gender}
                  </span>
                }
                sx={{
                  backgroundColor: "#fff",
                  marginRight: "7px",
                }}
              />
              <Chip
                icon={
                  <CalendarTodayOutlinedIcon
                    sx={{
                      fontSize: "16px",
                    }}
                  />
                }
                label={
                  <span
                    sx={{
                      fontSize: "16px",
                    }}
                  >
                    {formatDate(influencer?.createdAt)}
                  </span>
                }
                sx={{
                  backgroundColor: "#fff",
                  marginRight: "7px",
                  marginTop: "7px",
                }}
              />
            </Box>
          }
          titleTypographyProps={{
            fontSize: "24px",
          }}
          avatar={
            <CardMedia
              component="img"
              src={
                influencer?.pan_card === "fgf" ||
                influencer?.pan_card === null ||
                influencer?.pan_card === ""
                  ? logo
                  : influencer?.pan_card
              }
              sx={{
                width: "100px",
                height: "100px",
                borderRadius: "50%",
                marginRight: "16px",
              }}
            />
          }
          sx={{ padding: "0" }}
        />
        <CardContent sx={{ padding: "0", marginTop: "5px" }}>
          <Typography variant="body">
            {/* { truncateText(influencer?.bio, influencer?.bio?.length)} */}

            {influencer?.bio && influencer?.bio.length > 90
              ? `${influencer?.bio.slice(0, 90)}...`
              : influencer?.bio}
          </Typography>
          <Box sx={{ marginTop: "5px" }}>
            {firstThreeNiche.map((ele, i) => (
              <Chip
                key={i}
                label={ele}
                variant="outlined"
                sx={{
                  marginRight: "5px",
                  borderRadius: "10px",
                }}
              />
            ))}
            {remainingNiche && remainingNiche.length > 0 && (
              <Tooltip
                TransitionComponent={Fade}
                title={remainingNiche.join(", ")}
                arrow
              >
                <Chip
                  label={`+${remainingNiche.length}`}
                  variant="outlined"
                  sx={{ marginRight: "5px" }}
                />
              </Tooltip>
            )}
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
            mt={2}
          >
            <Card
              sx={{
                borderRadius: "15px",
                paddingX: "10px",
              }}
              elevation={0}
            >
              <CardHeader
                title="12K"
                subheader="Profile Name"
                sx={{
                  textAlign: "center",
                  flexDirection: "column",
                }}
                avatar={
                  <Avatar
                    src={facebookImage}
                    sx={{
                      marginBottom: "5px",
                    }}
                  />
                }
                titleTypographyProps={{
                  fontSize: "20px",
                }}
              />
            </Card>
            <Card
              sx={{
                borderRadius: "15px",
                paddingX: "10px",
              }}
              elevation={0}
            >
              <CardHeader
                title="12K"
                subheader="Profile Name"
                sx={{
                  textAlign: "center",
                  flexDirection: "column",
                }}
                // avatar={<Avatar sx={{backgroundColor : "#fff", border : "1px solid #fe6eb7"}}>
                //   <Instagram sx={{color : "#fe6eb7"}}/>
                // </Avatar>}
                avatar={
                  <Avatar
                    src={instagramImage}
                    sx={{
                      marginBottom: "5px",
                    }}
                  />
                }
                titleTypographyProps={{
                  fontSize: "20px",
                }}
              />
            </Card>
            <Card
              sx={{
                borderRadius: "15px",
                paddingX: "10px",
              }}
              elevation={0}
            >
              <CardHeader
                title="12K"
                subheader="Profile Name"
                sx={{
                  textAlign: "center",
                  flexDirection: "column",
                }}
                avatar={
                  <Avatar
                    src={youtubeImage}
                    sx={{
                      marginBottom: "5px",
                    }}
                  />
                }
                titleTypographyProps={{
                  fontSize: "20px",
                }}
              />
            </Card>
          </Box>
        </CardContent>
        <CardActions>
          <Button
            variant="contained"
            sx={{
              borderRadius: "30px",
              marginLeft: "auto",
              marginTop: "10px",
              textTransform: "none",
              color: "#fff",
              padding: "8px 25px",
              backgroundColor: theme.palette.primary.main,
              "&:hover": {
                color: "black",
                backgroundColor: "#fff",
              },
            }}
            onClick={() => navigate(`/influencer/${influencer.influencer_id}`)}
          >
            Show Details
          </Button>
        </CardActions>
      </Card>
    </>
  );
};

export default DemoCard;
