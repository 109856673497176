import "./App.css";
import ProjectRoutes from "./ProjectRoutes";

function App() {
  return (
    <>
      <ProjectRoutes />
    </>
  );
}

export default App;
