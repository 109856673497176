import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  data: [],
  loading: false,
  error: null,
};

export const addComment = createAsyncThunk(
  "comments/addComment",
  async (
    { newUserMessage, campaignAppId, influencerId, fileType },
    { getState, rejectWithValue }
  ) => {
    try {
      const state = getState();
      const token = state.otp.data.token;
      const brandId = state.otp.data.brands_id;
      console.log(newUserMessage);

      const data = {
        campaign_applied_id: campaignAppId,
        influencer_id: influencerId,
        brand_id: brandId,
        sender_type: "BRANDS",
        comment_text: newUserMessage?.message,
        comment_file: newUserMessage?.file || "",
        file_type: fileType || "",
        note_type: newUserMessage?.noteType || 0,
      };
      console.log(data);
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-type": "application/json",
        },
      };
      const response = await axios.post(
        `https://api.republicofinfluencers.in/v2/brands/comments/add`,
        data,
        config
      );
      // console.log(response);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const addCommentSlice = createSlice({
  name: "addComment",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addComment.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addComment.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(addComment.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default addCommentSlice.reducer;
