import * as React from "react";
import { useState } from "react";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import {
  Dialog,
  DialogContent,
} from "@mui/material";

function srcset(image, size, rows = 1, cols = 1) {
  return {
    src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
    srcSet: `${image}?w=${size * cols}&h=${
      size * rows
    }&fit=crop&auto=format&dpr=2 2x`,
  };
}

export default function ImageListing({ itemData }) {
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const randomInteger = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };
  const imageItemData = itemData?.map((ele) => ({
    id: ele?.campaign_images_id,
    img: ele?.image_link,
    // rows: randomInteger(1, 3),
    // cols: randomInteger(1, 3),
  }));

  const handleImageClick = (image) => {
    setSelectedImage(image);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  //   console.log(imageItemData);

  return (
    <>
      <ImageList
        //   sx={{ width: 500, height: 450 }}
        variant="quilted"
        cols={4}
        rowHeight={121}
      >
        {imageItemData.map((item) => (
          <ImageListItem
            key={item.id}
            cols={item.cols || 1}
            rows={item.rows || 1}
            onClick={() => handleImageClick(item)}
          >
            <img
              {...srcset(item.img, 121, item.rows, item.cols)}
              alt="imag"
              loading="lazy"
              style={{
                borderRadius : "10px"
              }}
            />
          </ImageListItem>
        ))}
      </ImageList>
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogContent>
          {selectedImage && (
            <img
              src={selectedImage.img}
              alt="Fullsize"
              style={{ width: "100%", height: "auto" }}
            />
          )}
        </DialogContent>
      </Dialog>
    </>
  );
}
