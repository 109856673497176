import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  data: [],
  loading: false,
  error: null,
};

export const fetchCampaignApplications = createAsyncThunk(
  "campaignApplications/fetch",
  async ({ campaignId, selectedCategory }, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const token = state.otp.data.token;
      console.log(campaignId)
      console.log(selectedCategory)

      const config = {
        method: "get",
        url: `https://api.republicofinfluencers.in/v2/brands/campaign_applications/get/${campaignId}?limit=500&offset=0&application_status=${selectedCategory}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      
      const response = await axios.request(config);

      console.log(response.data)
      return response.data;

    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const campaignApplicationsSlice = createSlice({
  name: "campaignApplications",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCampaignApplications.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCampaignApplications.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchCampaignApplications.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default campaignApplicationsSlice.reducer;
