import React from "react";
import { Navbar } from "../navbar/Navbar";
import { SideNav } from "../sidenav/SideNav";
import { Box } from "@mui/material";
import "../../Dash.css";
import { ToastContainer } from "react-toastify"; 
import "react-toastify/dist/ReactToastify.css";

const Layout = ({ children }) => {
  return (
    <>
      <div className="wrapper" >
        <Navbar />
        <Box sx={{ display: "flex" }}>
          <SideNav />
          <Box component="main" sx={{ flexGrow: 1 }}>
            <div className="page-wrapper" >
              <div className="page-content">{children}</div>
            </div>
          </Box>
        </Box>
      </div>
      <ToastContainer />
    </>
  );
};

export {Layout};
