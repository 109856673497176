import React, { useEffect, useState } from "react";
import { Container, Typography, Button, Box, Paper, Avatar } from "@mui/material";
import { useNavigate } from "react-router-dom";

const FbAuth = () => {
  const [params, setParams] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    const hash = window.location.hash.substring(1);
    const hashParams = new URLSearchParams(hash);
    const paramsObject = {
      access_token: hashParams.get('access_token'),
      data_access_expiration_time: hashParams.get('data_access_expiration_time'),
      long_lived_token: hashParams.get('long_lived_token'),
      expires_in: hashParams.get('expires_in'),
    };
    setParams(paramsObject);
  }, []);

  const handleClick = () => {
    const url = new URL("https://roi.republicofinfluencers.in/fb-login/");
    url.searchParams.append("access_token", params.access_token);
    url.searchParams.append("data_access_expiration_time", params.data_access_expiration_time);
    url.searchParams.append("long_lived_token", params.long_lived_token);
    url.searchParams.append("expires_in", params.expires_in);
    
    window.location.href = url.toString();
  };

  return (
    <>
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Paper
          elevation={3}
          sx={{
            padding: 4,
            maxWidth: 400,
            width: "100%",
            maxHeight: 400,
            height: "100%",
            borderRadius: "20px",
            backgroundColor : "rgb(255 240 254)",
            boxShadow : "none"
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mb: 2,
            }}
          >
            <Avatar
              src="https://play-lh.googleusercontent.com/pcc7vUKCaKj_gIoLIqyxmMysfyZKIrW_75GssJYiIM1BTpOJMspvwGzfeF2ENZB-wYo=w240-h480-rw"
              alt="Avatar"
              sx={{ width: 100, height: 100 }}
            />
          </Box>
          <Typography variant="h6" component="h2" gutterBottom align="center" fontWeight="700">
            Facebook authentication completed successfully.
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              mt: 10,
            }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={handleClick}
              sx={{
                borderRadius: "20px",
                background: "#AC009B",
                textTransform: "none",
                "&:hover": {
                  color: "black",
                  backgroundColor: "#fff",
                },
              }}
            >
              Continue
            </Button>
          </Box>
        </Paper>
      </Container>
    </>
  );
};

export default FbAuth;
