/* eslint-disable no-restricted-globals */
/* eslint-disable no-unused-vars */
import React from "react";
import { useSelector } from "react-redux";
import { Layout } from "../../components";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import SocialCard from "../influencer/components/SocialCard";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material";
import {
  Avatar,
  CardContent,
  Stack,
} from "@mui/material";
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import { editBrand } from "../../features/brandprofile/editBrandProfile";

const Profile = () => {
  const { register, handleSubmit, setValue, control } = useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();

  const { data: profileData } = useSelector(
    (state) => state.brandprofileget.data
  );
  if (!profileData) {
    return null;
  }
  const {
    brand_logo,
    name,
    email,
    number,
    website,
    address,
    city,
    state,
    brand_industries,
    overview,
    facebook_url,
    instagram_url,
    youtube_url,
  } = profileData;

  console.log(profileData);
  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const base64Data = event.target.result.split(",")[1];
        setValue("logo", base64Data);
      };
      reader.readAsDataURL(file);
    }
  };

  

  const onSubmit = async (data) => {
    try {
      const updatedData = {
        brand_logo: data.logo,
        brand_type_id: profileData?.brand_type_id,
        name: data.name,
        email: data.email,
        number: profileData?.number,
        pan_card: profileData?.pan_card,
        gst_number: profileData?.gst_number,
        website: data.website,
        address: data.address,
        overview: data.overview,
        city_id: profileData?.city_id,
        state_id: profileData?.state_id,
        industry_ids: profileData?.brand_industries.map(
          (ele) => ele?.industry_id
        ),
        facebook_url: profileData?.facebook_url,
        instagram_url: profileData?.instagram_url,
        youtube_url: profileData?.youtube_url,
      };
      console.log(updatedData);
      await dispatch(editBrand(updatedData));
      navigate("/");
    } catch (error) {
      console.log(error);
    }
  };
  const socialMediaData = [
    { icon: "facebook", url: profileData?.facebook_url },
    { icon: "instagram", url: profileData?.instagram_url },
    { icon: "youtube", url: profileData?.youtube_url },
  ].filter((socialMedia) => socialMedia.url);

 
  return (
    <>
      <Layout>
        <Grid
          container
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "center",
            textAlign: "center",
            flexGrow: 1,
            padding: "15px",
          }}
        >
          {/* First Grid: Profile Image */}
          <Grid
            item
            xs={12}
            sm={12}
            md={3}
            lg={3}
            xl={3}
            sx={{
              display: "flex",
              justifyContent: "center",
              width: { xs: "auto", md: "270px" }, // Set width to auto on smaller screens
            }}
          >
            <Avatar
              src={brand_logo}
              sx={{
                height: "270px",
                width: "270px",
              }}
            />
          </Grid>

          {/* Second Grid: Name and Overview */}
          <Grid
            item
            xs={12}
            sm={12}
            md={7}
            lg={8}
            xl={8}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "flex-start",
              marginLeft: { xs: 0, md: "20px" },
              width: "100%", // Make the second grid item take 100% width on smaller screens
            }}
          >
            <Typography variant="h3" sx={{ marginTop: "10px" }}>
              {name}
            </Typography>
            <Typography
              variant="h6"
              sx={{
                color: "gray",
                textAlign: "justify",
              }}
            >
              {overview}
            </Typography>
          </Grid>
        </Grid>
        {/* <Grid container sx={{display:"flex",justifyContent:"space-between"}}>
<Typography variant="h6">
Website : {website}
</Typography>
<Typography variant="h6">
state : {state?.state_name}
</Typography>
<Typography variant="h6">
city : {city?.city_name}
</Typography>
        </Grid> */}
        <Grid container>
          {socialMediaData.length > 0 && (
            <SocialCard socialMediaData={socialMediaData} />
          )}
        </Grid>
        <Grid container>
          <Grid item lg={8} xs={12} sm={12} md={12} xl={12}>
            <Paper
              sx={{
                borderRadius: "15px",
                marginTop: "10px",
                boxShadow: "none",
              }}
            >
              <Stack>
                <CardContent
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    flexWrap: "wrap",
                  }}
                >
                  <Box>
                    <Typography variant="h4">Profile Update</Typography>
                    <Typography variant="subtitle1">
                      Lorem ipsum dolor sit amet
                    </Typography>
                  </Box>
                </CardContent>
              </Stack>
            </Paper>
            <Box height={15} />
            <form
              style={{
                marginTop: "20px",
              }}
              onSubmit={handleSubmit(onSubmit)}
            >
              <Grid>
                <Grid container spacing={5} mb={3}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      id="name"
                      label="Name"
                      fullWidth
                      defaultValue={name}
                      {...register("name")}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      id="website"
                      label="Website"
                      fullWidth
                      defaultValue={website}
                      {...register("website", {
                        required: true,
                        pattern:
                          "(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?",
                      })}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={5} mb={3}>
                  <Grid item xs={12} md={4}>
                    <TextField
                      id="address"
                      label="Address"
                      fullWidth
                      defaultValue={address}
                      {...register("address")}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      id="email"
                      label="Email"
                      fullWidth
                      defaultValue={email}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      id="email"
                      label="Email"
                      fullWidth
                      defaultValue={email}
                      {...register("email", {
                        required: true,
                        pattern: /^\S+@\S+$/i,
                      })}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={5} mb={3}>
                  <Grid item xs={12} md={12}>
                    <TextField
                      id="overview"
                      label="Overview"
                      multiline
                      rows={3}
                      fullWidth
                      defaultValue={overview}
                      {...register("overview")}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={5} mb={3}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      id="number"
                      label="Number"
                      fullWidth
                      defaultValue={number}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} md={6} mb={3}>
                <TextField
                  fullWidth
                  type="file"
                  label="Logo"
                  id="logo"
                  placeholder="Logo"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => handleImageUpload(e)}
                />
              </Grid>
                </Grid>
                <Stack flexDirection="row" justifyContent="flex-end">
                  <Button
                    type="submit"
                    variant="contained"
                    sx={{
                      borderRadius: "30px",
                      marginLeft: "auto",
                      marginTop: "10px",
                      textTransform: "none",
                      color: "#fff",
                      padding: "8px 25px",
                      backgroundColor: theme.palette.primary.main,
                      "&:hover": {
                        color: "black",
                        backgroundColor: "#fff",
                      },
                    }}
                  >
                    Update Profile
                  </Button>
                </Stack>
              </Grid>
            </form>
          </Grid>
        </Grid>
      </Layout>
    </>
  );
};

export default Profile;
