import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  data: [],
  loading: false,
  error: null,
};

export const fetchCampaignImage = createAsyncThunk(
  "campaign/fetchCampaignImage",
  async (campaignId, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const token = state.otp.data.token;

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.get(
        `https://api.republicofinfluencers.in/v2/brands/campaign/campaign-image/${campaignId}`,
        config
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const campaignImageSlice = createSlice({
  name: "fetchCampaignImage",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(fetchCampaignImage.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(fetchCampaignImage.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchCampaignImage.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default campaignImageSlice.reducer;
