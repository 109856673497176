import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchInfluencerDetails } from "../../features/influencers/getInfluencerDetails";
import { Layout } from "../../components";
import { makeFavourite } from "../../features/influencers/makeFavourite";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import InstagramIcon from '@mui/icons-material/Instagram';
import PlayCircleFilledWhiteOutlinedIcon from '@mui/icons-material/PlayCircleFilledWhiteOutlined';
import {
  Avatar,
  Box,
  Card,
  Chip,
  Stack,
} from "@mui/material";
import MaleIcon from "@mui/icons-material/Male";
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import GoogleIcon from "@mui/icons-material/Google";
import TwitterIcon from "@mui/icons-material/Twitter";
import YouTubeIcon from "@mui/icons-material/YouTube";
import SimilarProfile from "./components/SimilarProfile";
import PieChart from "../../charts/PieChart";

const InfluencerDetails = () => {
  const { influencerId } = useParams();
  const dispatch = useDispatch();

  const [isFavorite, setIsFavorite] = useState(false);
  const [showSkeleton, setShowSkeleton] = useState(false);

  useEffect(() => {
    dispatch(fetchInfluencerDetails(influencerId));
  }, [dispatch, influencerId]);

  const influencer = useSelector((state) => state.influencerDetails?.data);
  const loading = useSelector((state) => state.influencerDetails?.loading);
  // const error = useSelector((state) => state.influencerDetails?.error);

  useEffect(() => {
    if (influencer) {
      const isFav =
        influencer?.data?.favourite_influencers[0]
          ?.favourite_influencer_flag === 0;
      setIsFavorite(isFav);
    }
  }, [influencer]);

  const handleToggleFavorite = () => {
    dispatch(makeFavourite(influencerId));
    setIsFavorite(!isFavorite);
  };

  useEffect(() => {
    if (loading) {
      setShowSkeleton(true);
    } else {
      setShowSkeleton(false);
    }
  }, [setShowSkeleton, loading]);

  const detailsData = influencer?.data;
  console.log(detailsData);

  return (
    <Layout>
      <Grid container>
        <Grid item xs={12} md={12} lg={12} xl={12}>
          <Box
            sx={{
              display: "flex",
              alignItems: "start",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
              gap={2}
            >
              <Avatar
                src={detailsData?.pan_card}
                sx={{ width: 200, height: 200 }}
              />
              <Stack flexDirection="column" gap={1}>
                <Typography variant="h3">{detailsData?.name}</Typography>
                <Chip label={detailsData?.gender} icon={<MaleIcon />} sx={{ width: "50%" }} />
                <Box>
                  <IconButton onClick={handleToggleFavorite}>
                    {isFavorite ? (
                      <FavoriteIcon sx={{ color: "red" }} />
                    ) : (
                      <FavoriteBorderIcon sx={{ color: "#ff0000" }} />
                    )}
                  </IconButton>
                </Box>
              </Stack>
            </Box>
            <Box>
              <IconButton>
                <FacebookRoundedIcon
                  sx={{
                    color: "#0866ff",
                    fontSize: "2.5vw",
                  }}
                />
              </IconButton>
              <IconButton>
                <GoogleIcon
                  sx={{
                    color: "#000",
                    fontSize: "2.5vw",
                  }}
                />
              </IconButton>
              <IconButton>
                <TwitterIcon
                  sx={{
                    color: "#1da1f2",
                    fontSize: "2.5vw",
                  }}
                />
              </IconButton>
              <IconButton>
                <YouTubeIcon
                  sx={{
                    color: "#ff0000",
                    fontSize: "2.5vw",
                  }}
                />
              </IconButton>
            </Box>
          </Box>
          <Box height={15} />
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography variant="h5" sx={{ color: "gray" }}>
              {detailsData?.bio}
            </Typography>
          </Box>
          <Box height={15} />
          <Box>
            <Typography variant="h4">Tags</Typography>
          </Box>
          <Box height={15} />
          <Box>
            <Stack flexDirection="row" gap={2}>
              {detailsData?.influencer_content_niches?.map((ele) => (
                <Chip
                key={ele?.influencer_content_niche_id}
                label={ele?.content_niche?.content_niche_name}
                variant="outlined"
                sx={{
                  borderRadius: "10px",
                }}
              />
              ))}
            </Stack>
          </Box>
          <Box height={15} />
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <Stack
                sx={{
                  backgroundColor: "rgb(245, 239, 247)",
                  padding: "20px",
                  borderRadius: "20px",
                }}
                spacing={1.5}
              >
                <Typography variant="h5">Social Media</Typography>
                <Card
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    flexDirection: "row",
                    padding: "10px",
                    borderRadius: "10px",
                  }}
                  elevation={0}
                >
                  <Stack flexDirection="row" alignItems="center">
                    <Avatar
                      sx={{
                        backgroundColor: "#fff",
                      }}
                    >
                      <FacebookRoundedIcon
                        sx={{
                          color: "#0866ff",
                        }}
                      />
                    </Avatar>
                    <Typography variant="body">Profile name </Typography>
                  </Stack>

                  <Typography variant="subtitle1">12k Following </Typography>
                </Card>
                <Card
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    flexDirection: "row",
                    padding: "10px",
                    borderRadius: "10px",
                  }}
                  elevation={0}
                >
                  <Stack flexDirection="row" alignItems="center">
                    <Avatar
                      sx={{
                        backgroundColor: "#fff",
                      }}
                    >
                      <InstagramIcon
                        sx={{
                          color: "#fe6eb7",
                        }}
                      />
                    </Avatar>
                    <Typography variant="body">Profile name </Typography>
                  </Stack>

                  <Typography variant="subtitle1">12k Following </Typography>
                </Card>
                <Card
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    flexDirection: "row",
                    padding: "10px",
                    borderRadius: "10px",
                  }}
                  elevation={0}
                >
                  <Stack flexDirection="row" alignItems="center">
                    <Avatar
                      sx={{
                        backgroundColor: "#fff",
                      }}
                    >
                      <YouTubeIcon
                        sx={{
                          color: "#ff0000",
                        }}
                      />
                    </Avatar>
                    <Typography variant="body">Profile name </Typography>
                  </Stack>

                  <Typography variant="subtitle1">12k Following </Typography>
                </Card>
              </Stack>
            </Grid>
            <Grid item xs={12} md={4}>
              <Stack
                sx={{
                  backgroundColor: "rgb(245, 239, 247)",
                  padding: "20px",
                  borderRadius: "20px",
                }}
                spacing={1.5}
              >
                <Typography variant="h5">Pricing Info</Typography>
                <Card
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    flexDirection: "row",
                    padding: "7px",
                    borderRadius: "10px",
                  }}
                  elevation={0}
                >
                  <Stack flexDirection="row" alignItems="center">
                    <Avatar
                      sx={{
                        backgroundColor: "#fff",
                      }}
                    >
                      <PlayCircleFilledWhiteOutlinedIcon
                        sx={{
                          color: "#000",
                        }}
                      />
                    </Avatar>
                    <Typography variant="body">Video Cost </Typography>
                  </Stack>

                  <Typography variant="subtitle1">₹ 202458 </Typography>
                </Card>
                <Card
                  elevation={0}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    flexDirection: "row",
                    padding: "7px",
                    borderRadius: "10px",
                  }}
                >
                  <Stack flexDirection="row" alignItems="center">
                    <Avatar
                      sx={{
                        backgroundColor: "#fff",
                      }}
                    >
                      <PlayCircleFilledWhiteOutlinedIcon
                        sx={{
                          color: "#000",
                        }}
                      />
                    </Avatar>
                    <Typography variant="body">Video Cost </Typography>
                  </Stack>

                  <Typography variant="subtitle1">₹ 202458 </Typography>
                </Card>
                <Card
                  elevation={0}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    flexDirection: "row",
                    padding: "7px",
                    borderRadius: "10px",
                  }}
                >
                  <Stack flexDirection="row" alignItems="center">
                    <Avatar
                      sx={{
                        backgroundColor: "#fff",
                      }}
                    >
                      <PlayCircleFilledWhiteOutlinedIcon
                        sx={{
                          color: "#000",
                        }}
                      />
                    </Avatar>
                    <Typography variant="body">Video Cost </Typography>
                  </Stack>

                  <Typography variant="subtitle1">₹ 202458 </Typography>
                </Card>
                <Card
                  elevation={0}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    flexDirection: "row",
                    padding: "7px",
                    borderRadius: "10px",
                  }}
                >
                  <Stack flexDirection="row" alignItems="center">
                    <Avatar
                      sx={{
                        backgroundColor: "#fff",
                      }}
                    >
                      <PlayCircleFilledWhiteOutlinedIcon
                        sx={{
                          color: "#000",
                        }}
                      />
                    </Avatar>
                    <Typography variant="body">Video Cost </Typography>
                  </Stack>

                  <Typography variant="subtitle1">₹ 202458 </Typography>
                </Card>
              </Stack>
            </Grid>
          </Grid>
        </Grid>
        {/*/!*<SimilarProfile/>*!/*/}
        {/*  <Grid item xs={12} md={12} lg={12} xl={12}>*/}
        {/*      <PieChart/>*/}
        {/*  </Grid>*/}

      </Grid>
    </Layout>
  );
};

export default InfluencerDetails;
