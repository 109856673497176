/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../features/auth/authSlice";
import { verifyOtp } from "../../features/auth/otpVerifySlice";
import { fetchCampaign } from "../../features/campaign/getCampagin";
import GoogleIcon from "@mui/icons-material/Google";
import AppleIcon from "@mui/icons-material/Apple";
import { toast } from "react-toastify";
import loginImage from "../../images/loginImage_3.svg";
import { ToastContainer } from "react-toastify";
import { Chip, Divider, IconButton, Stack } from "@mui/material";
import logo from "../../images/roi_logo.png";
import { useTheme } from "@mui/styles";

const defaultTheme = createTheme();

export default function Login() {
  const [showOtpField, setShowOtpField] = useState(false);
  const [mobileNumber, setMobileNumber] = useState("");
  const [otp, setOtp] = useState("");
  const [loading, setLoading] = useState(false);
  const [isverified, setisVerified] = useState(false);
  const [resendTimer, setResendTimer] = useState(120);
  const [currentColorIndex, setCurrentColorIndex] = useState(0);
  const colors = ["#4285F4", "#0F9D58", "#F4B400", "#DB4437"];
  const [otpSent, setOtpSent] = useState(false);

  const theme = useTheme();

  const dispatch = useDispatch();

  const verificationCode = useSelector((state) => state.auth.verificationCode);
  const navigate = useNavigate();
  const handleGoogleSignIn = () => {
    // Handle Google sign-in logic here
    alert("Google Button Clicked!");
  };

  const handleSendOtp = () => {
    setLoading(true);

    dispatch(login({ mobile_number: mobileNumber, email: "" }))
      .unwrap()
      .then(() => {
        setShowOtpField(true);
        setLoading(false);
        setResendTimer(120);
        toast.success("OTP sent successfully");
        setOtpSent(true);
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error sending OTP:", error);
      });
  };
  const handleVerifyOtp = () => {
    setLoading(true);

    dispatch(
      verifyOtp({ otp: Number(otp), verificationCode: verificationCode })
    )
      .then((response) => {
        setLoading(false);

        if (response.payload.status === 200) {
          toast.success("OTP verified successfully");
          setTimeout(() => {
            navigate("/");
          }, 1000);
          dispatch(fetchCampaign());
        } else {
          toast.error("OTP is not correct. Please enter the correct OTP.");
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error("An error occurred while verifying OTP.");
        console.error("Error verifying OTP:", error);
      });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
  };

  const isMobileNumberValid =
    mobileNumber.length === 10 && /^\d+$/.test(mobileNumber);

  const [hasTouchedNumberInput, setHasTouchedNumberInput] = useState(false);

  const handleNumberInputBlur = () => {
    if (!isMobileNumberValid) {
      setHasTouchedNumberInput(true);
    }
  };
  const handleResendOtp = () => {
    dispatch(login({ mobile_number: mobileNumber, email: "" }))
      .unwrap()
      .then(() => {
        setShowOtpField(true);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error sending OTP:", error);
      });
    setisVerified(false);
  };
  const handleChangeNumber = () => {
    setShowOtpField(false);
    setMobileNumber("");
    setOtp("");
    setisVerified(false);
  };

  useEffect(() => {
    if (resendTimer > 0) {
      const timerInterval = setInterval(() => {
        setResendTimer((prevTimer) => prevTimer - 1);
      }, 1000);

      return () => {
        clearInterval(timerInterval);
      };
    } else if (resendTimer === 0) {
      toast.error("OTP expired");
    }
  }, [resendTimer]);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      if (!showOtpField) {
        if (!otpSent) {
          handleSendOtp();
        }
      } else {
        handleVerifyOtp();
      }
    }
  };

  useEffect(() => {
    const rotateColor = () => {
      const nextColorIndex = (currentColorIndex + 1) % colors.length;
      setCurrentColorIndex(nextColorIndex);
    };
    const colorInterval = setInterval(rotateColor, 1000);

    return () => {
      clearInterval(colorInterval);
    };
  }, [currentColorIndex, colors]);

  const currentColor = colors[currentColorIndex];

  return (
    <ThemeProvider theme={defaultTheme}>
      <Grid
        container
        component="main"
        sx={{
          height: "100vh",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CssBaseline />
        <Grid
          container
          component="main"
          sx={{
            height: "100vh",
            justifyContent: "center",
            alignItems: "center",
            backgroundImage: `url(${loginImage})`,
            // backgroundImage: `url(https://source.unsplash.com/random?wallpapers)`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "auto",
            backgroundPosition: "center",
          }}
        >
          <Grid
            item
            xs={12}
            sm={8}
            md={4}
            component={Paper}
            elevation={6}
            sx={{
              borderRadius: "30px",
              backgroundColor: "rgba(255, 255, 255, 0.9)",
            }}
          >
            <Box
              sx={{
                py: 5,
                px: 6,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <Avatar
                  sx={{
                    m: 1,
                    objectFit: "contain",
                    borderRadius: "0px",
                    width: "89px",
                  }}
                  src={logo}
                >
                  {/* <LockOutlinedIcon /> */}
                </Avatar>
                <Typography variant="h4" mb={2} textAlign="center">
                  Hello! Welcome
                </Typography>
                <Typography variant="body" mb={2} textAlign="center">
                  Login With Your Data Which You Have Entered In Time Of
                  Register
                </Typography>
                <Typography component="h1" variant="h5" textAlign="center">
                  Sign in with
                </Typography>
              </Box>
              <Divider sx={{ color: "#fff", width: "20%" }} variant="middle" />
              <Box>
                <Stack direction="row" spacing={1} justifyContent="center">
                  <IconButton>
                    <GoogleIcon sx={{ color: currentColor }} />
                  </IconButton>
                  <IconButton>
                    <AppleIcon sx={{ color: "#000" }} />
                  </IconButton>
                </Stack>
              </Box>
              <Divider
                sx={{
                  mt: 2,
                  "::before": { width: "50%" },
                  "::after": { width: "50%" },
                }}
              >
                <Chip label="OR" />
              </Divider>
              {showOtpField && (
                <Typography
                  variant="body2"
                  sx={{ textAlign: "right", cursor: "pointer" }}
                  onClick={handleChangeNumber}
                >
                  Change Number
                </Typography>
              )}
              <Box
                component="form"
                noValidate
                onSubmit={handleSubmit}
                sx={{
                  mt: 1,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
                onKeyDown={handleKeyDown}
              >
                <TextField
                  margin="normal"
                  required
                  id="mobileNumber"
                  label="Mobile Number"
                  name="mobileNumber"
                  autoComplete="tel"
                  autoFocus
                  InputProps={{
                      style: {
                        borderRadius: "20px",
                      },
                    }}
                  disabled={showOtpField}
                  value={mobileNumber}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    if (/^\d+$/.test(inputValue) || inputValue === "") {
                      setMobileNumber(inputValue);
                    }
                  }}
                  onBlur={handleNumberInputBlur}
                  error={hasTouchedNumberInput && !isMobileNumberValid}
                  helperText={
                    hasTouchedNumberInput &&
                    !isMobileNumberValid &&
                    "Please enter a valid 10-digit number"
                  }
                  inputProps={{
                    maxLength: 10,
                  }}
                  onKeyDown={handleKeyDown}
                  sx={{ width: "75%" }}
                />

                {showOtpField && (
                  <TextField
                    margin="normal"
                    required
                    name="otp"
                    label="One-Time Password"
                    type="text"
                    id="otp"
                    InputProps={{
                      style: {
                        borderRadius: "20px",
                      },
                    }}
                    autoComplete="one-time-code"
                    inputProps={{ maxLength: 4 }}
                    value={otp}
                    onChange={(e) => setOtp(e.target.value)}
                    onKeyDown={handleKeyDown}
                    sx={{ width: "75%" }}
                  />
                )}

                {showOtpField && (
                  <Grid container textAlign="center">
                    <Grid item xs={6}>
                      <Button
                        fullWidth
                        variant="text"
                        sx={{
                          width: "auto",
                          textTransform: "none",
                          borderRadius: "30px",
                        }}
                        onClick={handleResendOtp}
                        disabled={resendTimer > 0}
                      >
                        Resend
                        {resendTimer > 0 ? ` ${resendTimer} sec` : ""}
                      </Button>
                    </Grid>
                    <Grid item xs={6}>
                      <Button
                        fullWidth
                        variant="contained"
                        sx={{
                          borderRadius: "30px",

                          textTransform: "none",
                          width: "auto",
                          padding: "8px 25px",

                          backgroundColor: theme.palette.primary.main,
                          "&:hover": {
                            color: "black",
                            backgroundColor: "#fff",
                          },
                        }}
                        onClick={handleVerifyOtp}
                        disabled={loading || !isMobileNumberValid}
                      >
                        {loading ? (
                          <CircularProgress size={24} color="secondary" />
                        ) : (
                          <Typography
                            variant="body"
                            sx={{ fontWeight: "bold" }}
                          >
                            Verify Otp
                          </Typography>
                        )}
                      </Button>
                    </Grid>
                  </Grid>
                )}

                {!showOtpField && (
                  <Button
                    variant="contained"
                    sx={{
                      borderRadius: "30px",
                      width: "50%",
                      marginTop: "10px",
                      textTransform: "none",
                      mb: 2,
                      padding: "8px 25px",
                      backgroundColor: theme.palette.primary.main,
                      "&:hover": {
                        color: "black",
                        backgroundColor: "#fff",
                      },
                    }}
                    onClick={handleSendOtp}
                    disabled={!isMobileNumberValid}
                  >
                    {loading ? (
                      <CircularProgress size={24} color="secondary" />
                    ) : (
                      <Typography variant="body" sx={{ fontWeight: "bold" }}>
                        Send Otp
                      </Typography>
                    )}
                  </Button>
                )}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <ToastContainer />
    </ThemeProvider>
  );
}
