import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios"; 

const initialState = {
  data: [],
  loading: false,
  error: null,
};

export const fetchState = createAsyncThunk(
  "resources/fetchState",
  async (_, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const token = state.otp.data.token;
      const response = await axios.get(
        "https://api.republicofinfluencers.in/v2/resources/get-state",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const getStateSlice = createSlice({
  name: "state",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchState.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchState.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchState.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default getStateSlice.reducer;
