import React, { useState } from "react";
import {
  Box,
  Typography,
  Grid,
  Card,
  CardMedia,
  Stack,
  Button,
  DialogContent,
  Dialog,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { fetchCampaignDetails } from "../../../features/campaign/campaignDetails";
import { updateCampaignContent } from "../../../features/campaignContent/updateCampaignContent";

const Content = ({ cardInfo }) => {
    const {campaignId} = useParams();
  const [selectedImage, setSelectedImage] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const dispatch = useDispatch();
  const handleAcceptContent = () => {
    dispatch(
      updateCampaignContent({
        appliedId: cardInfo?.campaign_applied_id,
        status: 1,
      })
    );
    toast.success("Content Accepted");
    dispatch(fetchCampaignDetails(
        campaignId 
      ))
  };
  const handleRejectContent = () => {
    dispatch(
      updateCampaignContent({
        appliedId: cardInfo?.campaign_applied_id,
        status: 2,
      })
    );
    toast.error("Denied");
  };
  const { data: campaignContent } = useSelector((state) =>
    state ? state.getCampaignContent.data : undefined
  );

  const handleImageClick = (image) => {
    setSelectedImage(image);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  console.log("cardInfo :", cardInfo);
  const { data: updateContentData } = useSelector(
    (state) => state.updateContentState.data
  );
  return (
    <>
      
        <Box>
          <Typography variant="h4" gutterBottom>
           {cardInfo?.campaign_application_content_status === 1 ? "Content Checked" : "Content Check" } 
          </Typography>
          {cardInfo?.campaign_application_content_status === -1 || cardInfo?.campaign_application_content_status === 1 ? (
          <Grid container spacing={2}>
            {campaignContent?.map((content) => (
              <Grid
                item
                xs={12}
                lg={6}
                md={6}
                sm={12}
                xl={6}
                key={
                  !content
                    ? undefined
                    : content?.campaign_application_content_id
                }
              >
                <Card elevation={0}>
                  {content?.link ? (
                    <CardMedia
                      component="img"
                      alt={`Image ${
                        !content
                          ? undefined
                          : content?.campaign_application_content_id
                      }`}
                      height="140"
                      sx={{
                        borderRadius: "10px",
                      }}
                      image={`${content?.link}`}
                      onClick={() => handleImageClick(content)}
                    />
                  ) : null}
                </Card>
              </Grid>
            ))}
          </Grid>
          ) : null}
          {cardInfo?.campaign_application_content_status &&
          cardInfo?.campaign_application_content_status === -1 ? (
            <Stack
              sx={{
                flexDirection: "row",
                justifyContent: "flex-end",
              }}
            >
              <Button type="text" onClick={handleAcceptContent}>
                Accept
              </Button>
              <Button type="text" onClick={handleRejectContent}>
                Reject
              </Button>
            </Stack>
          ) : cardInfo?.campaign_application_content_status === 1 ? (
            <Stack
              sx={{
                flexDirection: "row",
                justifyContent: "flex-end",
              }}
            >
              <Button type="text">Successfully reviewed</Button>
            </Stack>
          ) : (
            ""
          )}
        </Box>
      
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogContent>
          {selectedImage && selectedImage ? (
            <img
              src={selectedImage.link}
              alt="Fullsize"
              style={{ width: "100%", height: "auto" }}
            />
          ) : null}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Content;
