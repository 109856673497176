import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  data: [],
  loading: false,
  error: null,
};

export const getComments = createAsyncThunk(
  "comments/getComments",
  async ({influencerId,campaignAppId}, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const token = state.otp.data.token;
      console.log(influencerId,campaignAppId)

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-type": "application/json",
        },
      };
      const response = await axios.get(
        `https://api.republicofinfluencers.in/v2/brands/comments/get/${campaignAppId}/${influencerId}`,
        config
      );
      
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const getCommentsSlice = createSlice({
  name: "getComments",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getComments.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getComments.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(getComments.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default getCommentsSlice.reducer;
