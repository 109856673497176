import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  data: [],
  loading: false,
  error: null,
};

export const deleteComment = createAsyncThunk(
  "comments/deleteComment",
  async ({commentId}, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const token = state.otp.data.token;

      console.log(commentId)
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-type": "application/json",
        },
      };
      const response = await axios.delete(
        `https://api.republicofinfluencers.in/v2/brands/comments/delete/${commentId}`,
        config
      );
      console.log(response.data)
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const deleteCommentSlice = createSlice({
  name: "deleteComment",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(deleteComment.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteComment.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(deleteComment.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default deleteCommentSlice.reducer;
