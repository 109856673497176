import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 1365,
      xl: 1400,
      customBreakpoint: 1400,
    },
  },
  typography: {
    fontFamily: "'Urbanist', sans-serif",
  },
  palette: {
    primary: {
      main: "#45496a",
    },
    secondary: {
      main: "#7d8bae",
    },
    error: {
      main: "#e5857b",
    },
    // general: {
    //   main: "#f1b2b2",
    // },
    // arbitary: {
    //   main: "#e8ccc7",
    // },
  },
});

export default theme;
