import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios'; 

const initialState = {
  data: [],
  loading: false,
  error: null,
};

export const fetchCampaignDetails = createAsyncThunk(
  'campaign/fetchCampaignDetails',
  async (campaignId, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const token = state.otp.data.token;
      console.log(campaignId);

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axios.get(
        `https://api.republicofinfluencers.in/v2/brands/campaign/details/${campaignId}`,
        config
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const campaignDetailsSlice = createSlice({
  name: 'campaign',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCampaignDetails.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCampaignDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchCampaignDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default campaignDetailsSlice.reducer;
