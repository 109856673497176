import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { useSelector } from "react-redux"; 
import Login from "./pages/login_page/Login";
import Home from "./pages/home/Home";
import MyCampaign from "./pages/my campaigns/MyCampaign";
import CreateCampaign from "./pages/create campaign/CreateCampaign";
import Profile from "./pages/user profile/Profile";
import Influencer from "./pages/influencer/Influencer";
import InfluencerDetails from "./pages/influencer/InfluencerDetails";
import CompleteProfile from "./pages/completeprofile/CompleteProfile";
import CampaignDetails from "./pages/my campaigns/CampaignDetails";
import FavInfluencers from "./pages/fav Influencers/FavInfluencers";
import EditCampaign from "./pages/editcampaign/EditCampaign";
import AssetLinksJson from "./components/AssetLinksJson";
import FbAuth from "./pages/fb_access_page_mobile/FbAuth";

const PrivateRoute = ({ path, element }) => {
  const isAuthenticated = useSelector((state) => state.otp?.data?.status === 200 ? true : false);
  return isAuthenticated ? element : <Navigate to="/login" />;
};


const ProjectRoutes = () => {
  useEffect(() => {
    window.history.pushState(null, null, window.location.href);
    window.onpopstate = function(event) {
      window.history.go(1);
    };
  }, []);

  return (
    <>

      <Router>
        <Routes>
          <Route exact path="/login" element={<Login />} />
          <Route path="/" element={<PrivateRoute element={<Home />} />} />
          <Route path="/campaigns" element={<PrivateRoute element={<MyCampaign />} />} />
          <Route path="/campaigns/:campaignId" element={<PrivateRoute element={<CampaignDetails />} />} />
          <Route path="/create-campaign" element={<PrivateRoute element={<CreateCampaign />} />} />
          <Route path="/profile" element={<PrivateRoute element={<Profile />} />} />
          <Route path="/complete-profile" element={<PrivateRoute element={<CompleteProfile />} />} />
          <Route exact path="/influencer" element={<PrivateRoute element={<Influencer />} />} />
          <Route path="/influencer/:influencerId" element={<PrivateRoute element={<InfluencerDetails />} />} />
          <Route path="/fav-influencers" element={<PrivateRoute element={<FavInfluencers />} />} />
          <Route path="/edit-campaign/:campaignId" element={<PrivateRoute element={<EditCampaign />} />} />
          <Route path="/facebook-campaign-auth" element={<FbAuth/>}/>
        </Routes>
      </Router>
    </>
  );
};

export default ProjectRoutes;
