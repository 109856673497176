import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  data: [],
  loading: false,
  error: null,
};

export const updateCampaignApplications = createAsyncThunk(
  "campaignApplications/updateCampaignApplications",
  async (
    { campaignId, campaignAppliedId, applicationStatusId },
    { getState, rejectWithValue }
  ) => {
    try {
      const state = getState();
      const token = state.otp?.data?.token;

      const data = {
        application_status: applicationStatusId,
      };
      const numCampId = Number(campaignId);
      const config = {
        method: "patch",
        url: `https://api.republicofinfluencers.in/v2/brands/campaign_applications/update/${numCampId}?campaign_applied_id=${campaignAppliedId}`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-type": "application/json",
        },
        data: data,
      };
      const response = await axios.request(config);
      console.log(response);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const updateCampaignApplicationsSlice = createSlice({
  name: "updateCampaignApp",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(updateCampaignApplications.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateCampaignApplications.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(updateCampaignApplications.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default updateCampaignApplicationsSlice.reducer;
