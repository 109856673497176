/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
import React, { useCallback, useEffect, useState } from "react";
import { Layout } from "../../components";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { fetchCampaignDetails } from "../../features/campaign/campaignDetails";
import { fetchState } from "../../features/resources/getState";
import { fetchCity } from "../../features/resources/getCity";
import { fetchPlatform } from "../../features/resources/getPlatform";
import { fetchContentNiche } from "../../features/resources/getContentNiche";
import { fetchPaymentType } from "../../features/resources/getPaymentType";
import { fetchCampaignGoal } from "../../features/resources/getCampaignGoal";
import { fetchLanguage } from "../../features/resources/getLanguage";
import { fetchInfluencerType } from "../../features/resources/getInfluencerType";
import { fetchAge } from "../../features/resources/getAge";
import { fetchGender } from "../../features/resources/getGender";
import { toast } from "react-toastify";
import { useTheme } from "@mui/styles";
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  Grid,
  TextField,
  MenuItem,
  Autocomplete,
  Typography,
  Box,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { editCampaign } from "../../features/campaign/editCampaign";
import { useNavigate, useParams } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
// import sideLogo from "../../images/Company_details.svg";
import { styled } from "@mui/material/styles";
import Check from "@mui/icons-material/Check";
import PropTypes from "prop-types";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import CircularProgress from "@mui/material/CircularProgress";

const EditCampaign = () => {
  const { campaignId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const [stateIdMatch, setStateIdMatch] = useState(0);
  const [platform, setPlatform] = useState([]);
  const [nicheData, setNicheData] = useState([]);
  const [languData, setLanguData] = useState([]);
  const [activeStep, setActiveStep] = useState(0);
  const [submitting, setSubmitting] = useState(false);
  const [campImage, setCampImage] = useState([]);
  const [influencerCategories, setInfluencerCategories] = useState([]);
  const [influAge, setInfluAge] = useState([]);
  const [loading, setLoading] = useState(true);
  console.log(influAge);
  const [influGender, setInfluGender] = useState([]);
  const [influencerData, setInfluencerData] = useState({
    nano: 0,
    micro: 0,
    macro: 0,
    mega: 0,
    celeb: 0,
  });

  const [selectedPaymentType, setSelectedPaymentType] = useState("");
  const { data: campaign = {} } = useSelector(
    (state) => state.campaignDetails?.data
  );
  console.log(campaign);

  const initialInfluencerData = campaign?.campaign_number_of_influencers;

  const [dataFetched, setDataFetched] = useState(false);

  useEffect(() => {
    async function fetchData() {
      if (!dataFetched) {
        await dispatch(fetchCampaignDetails(campaignId));
        await dispatch(fetchCity(stateIdMatch));
        setLoading(false);
        setDataFetched(true);
      }
    }
    fetchData();
    dispatch(fetchState());
    dispatch(fetchPlatform());
    dispatch(fetchPaymentType());
    dispatch(fetchCampaignGoal());
    dispatch(fetchContentNiche());
    dispatch(fetchLanguage());
    dispatch(fetchInfluencerType());
    dispatch(fetchAge());
    dispatch(fetchGender());

    if (initialInfluencerData) {
      setInfluencerData({
        nano: initialInfluencerData[0].nano || 0,
        micro: initialInfluencerData[0].micro || 0,
        macro: initialInfluencerData[0].macro || 0,
        mega: initialInfluencerData[0].mega || 0,
        celeb: initialInfluencerData[0].celeb || 0,
      });
    }
  }, [stateIdMatch, dispatch, campaignId, initialInfluencerData, dataFetched]);

  const handlePaymentTypeChange = (event) => {
    setSelectedPaymentType(event.target.value);
  };
  const handleInputChange = (category, value) => {
    setInfluencerData((prevData) => ({
      ...prevData,
      [category]: value,
    }));
  };
  const [deliverablesData, setDeliverablesData] = useState({
    Post: { nano: 0, micro: 0, macro: 0, mega: 0, celeb: 0 },
    Story: { nano: 0, micro: 0, macro: 0, mega: 0, celeb: 0 },
    Reel: { nano: 0, micro: 0, macro: 0, mega: 0, celeb: 0 },
    YoutubeVideo: { nano: 0, micro: 0, macro: 0, mega: 0, celeb: 0 },
  });

  const handleDelInputChange = (category, type, value) => {
    setDeliverablesData((prevData) => ({
      ...prevData,
      [category]: {
        ...prevData[category],
        [type]: value,
      },
    }));
  };

  const { data: ageData = [] } = useSelector(
    (state) => state.getAgeOfAudience?.data
  );

  const optionsAge = ageData?.map((item) => ({
    value: item.target_adience_age_group_id,
    label: item.target_adience_age_group,
  }));

  const { data: genderData = [] } = useSelector(
    (state) => state.getGender.data
  );

  const optionsGender = genderData?.map((item) => ({
    value: item.target_audience_gender_id,
    label: item.target_audience_gender_name,
  }));

  //state data fetched
  const { data: StateDataPull = [] } = useSelector(
    (state) => state.stateData?.data
  );
  const stateId = StateDataPull?.map((stateObj) => stateObj.state_id);

  const { data: InfluencerType = [] } = useSelector(
    (state) => state.influencerType?.data
  );

  const handleNext = () => {
    setActiveStep((prevStep) => prevStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const handleChange = (e) => {
    const index = e.target.value;
    stateId.filter(() => {
      if (Number(index)) {
        setStateIdMatch(index);
      } else {
        console.log("not Matched");
      }
    });
  };

  const steps = [
    "Step 1: Campaign Basics",
    "Step 2: Target Audience and Influencer Details",
    "Step 3: Collaboration Terms",
  ];
  //city data fetched
  const { data: cityData = [] } = useSelector((state) => state.cityData?.data);

  //Platform data fetched

  const { data: platformData = [] } = useSelector(
    (state) => state.platform?.data
  );

  // payment type fetched

  const { data: paymentData = [] } = useSelector(
    (state) => state.paymentType?.data
  );

  // campaign Goal fetched
  const { data: goalData = [] } = useSelector(
    (state) => state.campaignGoal?.data
  );

  // content niche Fetched

  const { data: contentNicheData = [] } = useSelector(
    (state) => state.contentNiche?.data
  );

  //Language Data Fetched
  const { data: lanData = [] } = useSelector(
    (state) => state.languageData?.data
  );

  const {
    control,
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const handleImageUpload = (e) => {
    const files = e.target.files;
    if (campImage.length + files.length > 5) {
      alert("You can only upload up to 5 images.");

      return (e.target.value = null);
    }

    // Loop through the selected files and add them to the campImage array
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      if (file) {
        const reader = new FileReader();
        reader.onload = (event) => {
          const base64Data = event.target.result.split(",")[1];
          setCampImage((prevImages) => [...prevImages, base64Data]);
        };
        reader.readAsDataURL(file);
      }
    }
  };

  //sending the data to api

  const onSubmit = async (data) => {
    try {
      const platformValue = platform.map((el) => el.value);
      const contentNicheValue = nicheData.map((el) => el.value);
      const languageValue = languData.map((el) => el.value);
      const formattedData = {
        campaign_id: campaignId,
        campaign_goal_id: parseInt(data.Goal),
        // campaign_status_id: parseInt(data.campaignStatus),
        campaign_status_id: 1,
        payment_status_id: parseInt(data.paymentType),
        content_niche_id: contentNicheValue.map(Number),
        platform_id: platformValue.map(Number),
        campaign_name: data.Name,
        location: data.City,
        campaign_about: data.About,
        language_id: languageValue.map(Number),
        campaign_start_dt: data.startDate,
        campaign_end_dt: data.endDate,
        campaign_budget: parseInt(data.Budget),
        image_link: campImage,
        eligibility: 1,
        ...influencerData,
        campaign_deliverables_info: InfluencerType.map(
          ({ influencer_type_id, type }) => {
            const deliverablesCount = {
              post: parseInt(deliverablesData.Post[type.toLowerCase()]),
              story: parseInt(deliverablesData.Story[type.toLowerCase()]),
              reels: parseInt(deliverablesData.Reel[type.toLowerCase()]),
              youtube: parseInt(
                deliverablesData.YoutubeVideo[type.toLowerCase()]
              ),
            };
            return {
              influencer_type_id,
              ...deliverablesCount,
            };
          }
        ),
        about_product: data.campaignBrief,
        barter_note: data.Barternotes || null,
        additional_notes: data.additionalnotes,
        content_submission_deadline: data.SubmissionDate,
        content_upload_date: data.UploadDate,
        target_audience_gender_id: influGender.map((el) => el.value),
        target_adience_age_group_id: influAge.map((el) => el.value),
      };
      console.log(formattedData);
      setSubmitting(true);
      await dispatch(editCampaign(formattedData));
      toast.success("Campaign created");
      setTimeout(() => {
        navigate("/campaigns");
      }, 2000);
    } catch (error) {
      console.error("Error submitting form:", error);
      toast.error("Error");
    } finally {
      setSubmitting(false);
    }
  };
  const QontoConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 10,
      left: "calc(-50% + 16px)",
      right: "calc(50% + 16px)",
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: "#000",
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: "#000",
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      borderColor:
        theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
      borderTopWidth: 3,
      borderRadius: 1,
    },
  }));

  const QontoStepIconRoot = styled("div")(({ theme, ownerState }) => ({
    color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#eaeaf0",
    display: "flex",
    height: 22,
    alignItems: "center",
    ...(ownerState.active && {
      color: "#000",
    }),
    "& .QontoStepIcon-completedIcon": {
      color: "#000",
      zIndex: 1,
      fontSize: 18,
    },
    "& .QontoStepIcon-circle": {
      width: 8,
      height: 8,
      borderRadius: "50%",
      backgroundColor: "currentColor",
    },
  }));

  function QontoStepIcon(props) {
    const { active, completed, className } = props;

    return (
      <QontoStepIconRoot ownerState={{ active }} className={className}>
        {completed ? (
          <Check className="QontoStepIcon-completedIcon" />
        ) : (
          <div className="QontoStepIcon-circle" />
        )}
      </QontoStepIconRoot>
    );
  }

  QontoStepIcon.propTypes = {
    /**
     * Whether this step is active.
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool,
  };



  const campaignPlatformIds = campaign?.campaign_platforms.map(
    (ele) => ele.platform?.platform_id
  );

  return (
    <>
      <Layout>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
          }}
        >
          {/* <div style={{ position: "relative" }}>
            <img
              src={sideLogo}
              alt=""
              style={{
                // width: "100%",
                height: "85vh",
                borderRadius: "15px",
              }}
            />
          </div> */}

          <div className="container mt-3">
            <h4>Edit Your Campaign</h4>
            {loading ? (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <CircularProgress
                  sx={{
                    color: "#000",
                  }}
                />
              </Box>
            ) : (
              <form onSubmit={handleSubmit(onSubmit)}>
                <Stepper
                  activeStep={activeStep}
                  connector={<QontoConnector />}
                  alternativeLabel
                  mt={2}
                  mb={2}
                >
                  {steps.map((label) => (
                    <Step key={label}>
                      <StepLabel StepIconComponent={QontoStepIcon}>
                        {label}
                      </StepLabel>
                    </Step>
                  ))}
                </Stepper>
                {activeStep === 0 && (
                  <Grid mt={3}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6} mb={3}>
                        <TextField
                          label="Campaign Title"
                          id="name"
                          variant="outlined"
                          defaultValue={campaign && campaign?.campaign_name}
                          fullWidth
                          placeholder="Enter a captivating title for your campaign."
                          {...register("Name", { required: true })}
                        />
                      </Grid>
                      <Grid item xs={12} md={6} mb={3}>
                        <TextField
                          select
                          label="Campaign Goals"
                          placeholder="Select the primary goal of your campaign:"
                          variant="outlined"
                          defaultValue={campaign?.campaign_goal_id}
                          id="goal"
                          fullWidth
                          {...register("Goal")}
                        >
                          <MenuItem value="">Select goal</MenuItem>
                          {goalData.map((goalObj) => (
                            <MenuItem
                              key={goalObj.campaign_goal_id}
                              value={goalObj.campaign_goal_id}
                            >
                              {goalObj.goal_name}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} md={6} mb={3}>
                      <Controller
                        name="platform"
                        control={control}
                        render={({ field }) => (
                          <Autocomplete
                            multiple
                            id="platform"
                            options={platformData.map((platformObj) => ({
                              value: platformObj.platform_id,
                              label: platformObj.platform_name,
                            }))}
                            value={platform}
                            defaultValue={campaignPlatformIds}
                            onChange={(_, selectedOptions) => {
                              setPlatform(selectedOptions);
                              field.onChange(selectedOptions);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                label="Platform Selection"
                                placeholder="Indicate the platforms influencers can use for their content (e.g., Instagram, Facebook, YouTube)."
                              />
                            )}
                            getOptionDisabled={(option) =>
                              platform.some(
                                (selectedOption) =>
                                  selectedOption.value === option.value
                              )
                            }
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} mb={3}>
                      <TextField
                        label="About Products/Services"
                        id="about"
                        defaultValue={campaign?.campaign_about}
                        variant="outlined"
                        multiline
                        fullWidth
                        {...register("About", {})}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} mb={3}>
                      <TextField
                        label="Campaign Description"
                        placeholder="Provide a detailed description of your campaign, including key information and expectations."
                        id="campaignBrief"
                        defaultValue={campaign?.about_product}
                        variant="outlined"
                        multiline
                        fullWidth
                        {...register("campaignBrief", {})}
                      />
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6} mb={3}>
                        <TextField
                          type="file"
                          id="campaignImage"
                          label="Campaign Cover Image"
                          fullWidth
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{
                            multiple: true,
                          }}
                          onChange={(event) => handleImageUpload(event)}
                        />
                      </Grid>
                      <Grid item xs={12} md={6} mb={3}>
                        <Controller
                          name="Language"
                          control={control}
                          render={({ field }) => (
                            <Autocomplete
                              multiple
                              id="language"
                              options={lanData?.map((languageObj) => ({
                                value: languageObj.language_id,
                                label: languageObj.language_name,
                              }))}
                              value={languData}
                              onChange={(_, selectedOptions) => {
                                setLanguData(selectedOptions);
                                field.onChange(selectedOptions);
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="outlined"
                                  placeholder="Select Language"
                                  fullWidth
                                />
                              )}
                              getOptionDisabled={(option) =>
                                languData.some(
                                  (selectedOption) =>
                                    selectedOption.value === option.value
                                )
                              }
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                )}
                {activeStep === 1 && (
                  <Grid>
                    <Grid container spacing={2} mb={3} mt={2}>
                      <Grid item xs={12} md={6}>
                        <TextField
                          select
                          variant="outlined"
                          fullWidth
                          label="State"
                          id="state"
                          {...register("State", { required: true })}
                          onChange={handleChange}
                        >
                          <MenuItem value="">Select State</MenuItem>
                          {StateDataPull.map((stateObj, index) => (
                            <MenuItem key={index} value={stateObj.state_id}>
                              {stateObj.state_name}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          select
                          id="city"
                          label="City"
                          variant="outlined"
                          defaultValue={campaign?.location}
                          fullWidth
                          {...register("City")}
                        >
                          <MenuItem value="">Select City</MenuItem>
                          {cityData.map((cityObj) => (
                            <MenuItem
                              key={cityObj.city_id}
                              value={cityObj.city_name}
                            >
                              {cityObj.city_name}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} mb={3}>
                      <Grid item xs={12} md={6}>
                        <label htmlFor="startDate" className="form-label">
                          Start Date
                        </label>
                        <TextField
                          type="date"
                          className="form-control"
                          defaultValue={new Date(campaign?.campaign_start_dt)
                            .toISOString()
                            .substr(0, 10)}
                          id="startDate"
                          variant="outlined"
                          {...register("startDate", { required: true })}
                        />
                      </Grid>

                      <Grid item xs={12} md={6}>
                        <label htmlFor="endDate" className="form-label">
                          End Date
                        </label>
                        <TextField
                          type="date"
                          className="form-control"
                          defaultValue={new Date(campaign?.campaign_end_dt)
                            .toISOString()
                            .substr(0, 10)}
                          id="endDate"
                          variant="outlined"
                          {...register("endDate", { required: true })}
                        />
                      </Grid>
                    </Grid>
                    <Grid item xs={12} md={6} mb={3}>
                      <Controller
                        name="contentNiche"
                        control={control}
                        render={({ field }) => (
                          <Autocomplete
                            multiple
                            id="contentNiche"
                            options={contentNicheData.map((nicheObj) => ({
                              value: nicheObj.content_niche_id,
                              label: nicheObj.content_niche_name,
                            }))}
                            value={nicheData}
                            onChange={(_, selectedOptions) => {
                              setNicheData(selectedOptions);
                              field.onChange(selectedOptions);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                placeholder="Select Content Niche(s)"
                                fullWidth
                              />
                            )}
                            getOptionDisabled={(option) =>
                              nicheData.some(
                                (selectedOption) =>
                                  selectedOption.value === option.value
                              )
                            }
                          />
                        )}
                      />
                    </Grid>
                    <Stack flexDirection="row" mb={3}>
                      <Typography type="h6">Target Demographics:</Typography>
                      <Typography type="body2">
                        Specify the demographics of your target audience.
                      </Typography>
                    </Stack>
                    <Grid container spacing={2} mb={3}>
                      <Grid item xs={12} md={6}>
                        <Controller
                          name="age"
                          control={control}
                          render={({ field }) => (
                            <Autocomplete
                              multiple
                              id="age"
                              options={ageData?.map((item) => ({
                                value: item.target_adience_age_group_id,
                                label: item.target_adience_age_group,
                              }))}
                              value={influAge}
                              onChange={(_, selectedOptions) => {
                                setInfluAge(selectedOptions);
                                field.onChange(selectedOptions);
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="outlined"
                                  label="Age Selection"
                                />
                              )}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Controller
                          name="gender"
                          control={control}
                          render={({ field }) => (
                            <Autocomplete
                              multiple
                              id="gender"
                              options={optionsGender}
                              value={influGender}
                              onChange={(_, selectedOptions) => {
                                setInfluGender(selectedOptions);
                                field.onChange(selectedOptions);
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="outlined"
                                  label="Gender Selection"
                                />
                              )}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} mb={3}>
                      <Grid item xs={12} md={6}>
                        <TableContainer component={Paper} elevation={0}>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell>Category</TableCell>
                                <TableCell>No. of Influencers</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {Object.entries(influencerData).map(
                                ([category, count]) => (
                                  <TableRow key={category}>
                                    <TableCell>{category}</TableCell>
                                    <TableCell>
                                      <TextField
                                        type="number"
                                        variant="standard"
                                        value={count}
                                        onChange={(e) =>
                                          handleInputChange(
                                            category,
                                            e.target.value
                                          )
                                        }
                                      />
                                    </TableCell>
                                  </TableRow>
                                )
                              )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Grid>

                      <Grid item xs={12} md={6}>
                        <Box>
                          <label
                            htmlFor="SubmissionDate"
                            className="form-label"
                          >
                            Content Submission Deadline: Select a deadline for
                            an influencer to upload content
                          </label>
                          <TextField
                            type="date"
                            className="form-control"
                            defaultValue={new Date(
                              campaign?.content_submission_deadline
                            )
                              .toISOString()
                              .substr(0, 10)}
                            id="SubmissionDate"
                            variant="outlined"
                            {...register("SubmissionDate", { required: true })}
                          />
                        </Box>
                        <Box mt={4}>
                          <label htmlFor="UploadDate" className="form-label">
                            Content Upload Date: Choose the date or date range
                            for uploading content.
                          </label>
                          <TextField
                            type="date"
                            className="form-control"
                            id="UploadDate"
                            defaultValue={new Date(
                              campaign?.content_upload_date
                            )
                              .toISOString()
                              .substr(0, 10)}
                            variant="outlined"
                            {...register("UploadDate", { required: true })}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
                {activeStep === 2 && (
                  <Grid mt={3}>
                    <Grid container spacing={2} mb={3}>
                      <Grid item xs={12} md={6}>
                        <TableContainer component={Paper} elevation={0}>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell>Deliverables</TableCell>
                                {InfluencerType.map((type) => (
                                  <TableCell key={type.influencer_type_id}>
                                    {type.type}
                                  </TableCell>
                                ))}
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {Object.entries(deliverablesData).map(
                                ([category, types]) => (
                                  <TableRow key={category}>
                                    <TableCell>{category}</TableCell>
                                    {Object.values(types).map(
                                      (count, index) => (
                                        <TableCell key={index}>
                                          <TextField
                                            type="number"
                                            variant="standard"
                                            value={count}
                                            onChange={(e) =>
                                              handleDelInputChange(
                                                category,
                                                Object.keys(types)[index],
                                                e.target.value
                                              )
                                            }
                                          />
                                        </TableCell>
                                      )
                                    )}
                                  </TableRow>
                                )
                              )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          select
                          id="paymentType"
                          label="Collaboration Type"
                          variant="outlined"
                          fullWidth
                          {...register("paymentType")}
                          onChange={handlePaymentTypeChange}
                        >
                          <MenuItem value="">
                            Choose the collaboration type for this campaign
                          </MenuItem>
                          {paymentData.map((paymentObj) => (
                            <MenuItem
                              key={paymentObj.campaign_payment_type_id}
                              value={paymentObj.campaign_payment_type_id}
                            >
                              {paymentObj.name}
                            </MenuItem>
                          ))}
                        </TextField>
                        {selectedPaymentType === 2 && (
                          <TextField
                            multiline
                            fullWidth
                            id="Barternotes"
                            label="Additional Barter Terms"
                            placeholder="Specify the offerings you provide in exchange for collaboration if it's a barter arrangement. For example, free products, services, event invitations, etc."
                            sx={{
                              marginTop: "10px",
                            }}
                            {...register("Barternotes", { required: true })}
                          />
                        )}
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} mb={3}>
                      <Grid item xs={12} md={6}>
                        <TextField
                          multiline
                          fullWidth
                          id="additionalnotes"
                          value={campaign?.additional_notes}
                          label="Additional Collaboration Terms"
                          placeholder="Outline any specific terms or requirements for influencers participating in your campaign."
                          {...register("additionalnotes", { required: true })}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          label="Budget"
                          variant="outlined"
                          id="budget"
                          placeholder="Budget"
                          value={campaign?.campaign_budget}
                          fullWidth
                          {...register("Budget", { required: true })}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                )}
                {activeStep !== 0 && (
                  <Button
                    variant="outlined"
                    sx={{
                      borderRadius: "20px",
                      padding: "8px 25px",
                      color: theme.palette.secondary.main,
                      border: "1px solid theme.palette.secondary.main",
                      textTransform: "none",
                      marginRight: "10px",
                      "&:hover": {
                        border: "1px solid #000 ",
                        color: "#000",
                      },
                    }}
                    onClick={handleBack}
                  >
                    Back
                  </Button>
                )}
                {activeStep !== steps.length - 1 ? (
                  <Button
                    variant="contained"
                    onClick={handleNext}
                    sx={{
                      borderRadius: "20px",
                      padding: "8px 25px",
                      backgroundColor: theme.palette.secondary.main,
                      color: "#fff",
                      textTransform: "none",
                      "&:hover": {
                        color: "#000",
                        backgroundColor: "#fff",
                        boxShadow: "none",
                      },
                    }}
                  >
                    Next
                  </Button>
                ) : (
                  <Button
                    type="button"
                    variant="contained"
                    onClick={() => handleSubmit(onSubmit)()}
                    disabled={submitting}
                    sx={{
                      borderRadius: "30px",
                      marginLeft: "auto",
                      textTransform: "none",
                      color: "#fff",
                      padding: "8px 25px",
                      backgroundColor: theme.palette.primary.main,
                      "&:hover": {
                        color: "black",
                        backgroundColor: "#fff",
                      },
                    }}
                  >
                    {submitting ? "Submitting..." : "Submit"}
                  </Button>
                )}
              </form>
            )}
          </div>
        </div>
      </Layout>
    </>
  );
};

export default EditCampaign;
