import React, { useEffect, useState } from "react";
import { useTheme } from "@mui/styles";
import {
  Card,
  CardContent,
  Typography,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Avatar,
  CardActionArea,
  CardHeader,
  Stack,
  Skeleton,
  Box,
  Divider,
} from "@mui/material";
import { uploadDefault } from "../../../images/uploaddemo.png";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import LanguageIcon from "@mui/icons-material/Language";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import logo from "../../../images/profileimage.jpg";
import { Edit, Delete } from "@mui/icons-material";
import { deleteCampaign } from "../../../features/campaign/deleteCampaign";
import { fetchCampaign } from "../../../features/campaign/getCampagin";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const CampCard = ({ campaign }) => {
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showSkeleton, setShowSkeleton] = useState(true);
  console.log(campaign);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const cId = campaign?.id;

  const deleteHandler = async (campaignId) => {
    try {
      await dispatch(deleteCampaign(campaignId));
      dispatch(fetchCampaign());
      setOpen(false);
    } catch (error) {
      console.error("Error deleting campaign:", error);
    }
  };
  useEffect(() => {
    if (campaign?.image) {
      setShowSkeleton(false);
    }
  }, [campaign]);

  return (
    <>
      <Card
  sx={{ borderRadius: "20px", backgroundColor: "#F5F7FF" }}
  elevation={0}
>
  {showSkeleton ? (
    <Skeleton>
      <Carousel
        showArrows={false}
        showThumbs={false}
        emulateTouch
        autoPlay={true}
      >
        {campaign?.image.map((image) => (
          <div key={image?.id}>
            <img
              src={image?.image_link}
              alt="bannerImage"
              style={{
                height: "200px", 
                width: "100%", 
                objectFit: "cover",
              }}
            />
          </div>
        ))}
      </Carousel>
    </Skeleton>
  ) : (
    <Carousel
      showArrows={false}
      showThumbs={false}
      emulateTouch
      autoPlay={true}
      infiniteLoop={true}
    >
      {campaign?.image.map((image) => (
        <div key={image?.id}>
          <img
            src={image?.image_link}
            alt="bannerImage"
            style={{
              height: "200px", 
              width: "100%", 
              objectFit: "cover",
            }}
          />
        </div>
      ))}
    </Carousel>
  )}
        <CardActionArea onClick={() => navigate(`/campaigns/${campaign?.id}`)}>
          <CardContent sx={{ padding: "20px" }}>
            <CardHeader
              title={
                showSkeleton ? (
                  <Skeleton width="60%" />
                ) : campaign?.title.length > 23 ? (
                  `${campaign?.title?.slice(0, 23)}...`
                ) : (
                  campaign?.title
                )
              }
              subheader="Location"
              sx={{
                padding: 0,
                marginBottom: "15px",
              }}
              avatar={<Avatar src={logo} sx={{ marginRight: "16px" }} />}
              titleTypographyProps={{
                fontSize: "24px",
              }}
            />
            <Typography
              variant="subtitle1"
              color="text.secondary"
              style={{ lineHeight: "1.2" }}
            >
              {showSkeleton ? (
                <Skeleton width="80%" />
              ) : campaign?.about.length > 90 ? (
                `${campaign?.about?.slice(0, 90)}...`
              ) : (
                campaign?.about
              )}
            </Typography>

            <Stack flexDirection="row" gap={1} mb={2} mt={2}>
              <Chip
                label="language"
                variant="outlined"
                icon={<LanguageIcon sx={{ fontSize: "17px" }} />}
              />
              <Chip
                label="Paid"
                variant="outlined"
                icon={<CurrencyRupeeIcon sx={{ fontSize: "17px" }} />}
              />
            </Stack>
            <Stack flexDirection="row" gap={1} alignItems="center">
              <Chip
                label="Automobile"
                variant="outlined"
                sx={{
                  border: "none",
                }}
              />
              <Divider
                orientation="vertical"
                sx={{
                  border: "0.25px solid #000",
                  alignItems: "none",
                  height: "20px",
                }}
              />
              <Chip
                label="Gaming"
                variant="outlined"
                sx={{
                  border: "none",
                }}
              />
              <Divider
                orientation="vertical"
                sx={{
                  border: "0.25px solid #000",
                  alignItems: "none",
                  height: "20px",
                }}
              />
              <Chip
                label="Life Style"
                variant="outlined"
                sx={{
                  border: "none",
                }}
              />
            </Stack>
          </CardContent>
        </CardActionArea>
        <Stack
          flexDirection="row"
          justifyContent="flex-end"
          sx={{ padding: "0 20px 20px 20px" }}
        >
          <Button
            variant="outlined"
            sx={{
              borderRadius: "20px",
              color: theme.palette.secondary.main,
              border: "1px solid theme.palette.secondary.main",
              textTransform: "none",
              marginRight: "10px",
              "&:hover": {
                border: "1px solid #000 ",
                color: "#000",
              },
            }}
            onClick={handleClickOpen}
          >
            <Delete
              sx={{
                marginRight: "5px",
                fontSize: "18px",
              }}
            />
            Delete
          </Button>

          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            sx={{ borderRadius: "10px" }}
          >
            <Box>
              <DialogTitle id="alert-dialog-title">
                {`Do You Want Delete This Campaign : `}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  {campaign?.title}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  style={{
                    borderRadius: "20px",
                    color: "#000",
                    textTransform: "none",
                    padding: "5px",
                  }}
                  onClick={handleClose}
                >
                  Cancel
                </Button>
                <Button
                  onClick={() => deleteHandler(cId)}
                  autoFocus
                  style={{
                    borderRadius: "20px",
                    // backgroundColor: "#ff5252",
                    backgroundColor: theme.palette.primary.main,
                    color: "#fff",
                    textTransform: "none",
                    padding: "5px",
                  }}
                >
                  Delete
                </Button>
              </DialogActions>
            </Box>
          </Dialog>

          <Button
            variant="contained"
            sx={{
              borderRadius: "30px",
             
              
              textTransform: "none",
              color: "#fff",
              padding: "8px 25px",
              backgroundColor: theme.palette.primary.main,
              "&:hover": {
                color: "black",
                backgroundColor: "#fff",
              },
            }}
            onClick={() => navigate(`/edit-campaign/${campaign?.id}`)}
          >
            <Edit
              sx={{
                marginRight: "7px",
                fontSize: "18px",
              }}
            />
            Edit
          </Button>
        </Stack>
      </Card>
    </>
  );
};

export default CampCard;
