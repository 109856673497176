import React, { useEffect, useState } from "react";
import { Layout } from "../../components";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchCampaignDetails } from "../../features/campaign/campaignDetails";
import PlayCircleFilledWhiteOutlinedIcon from "@mui/icons-material/PlayCircleFilledWhiteOutlined";
import {
  Avatar,
  Typography,
  Grid,
  CardContent,
  Card,
  Box,
  Stack,
  Skeleton,
  Chip,
} from "@mui/material";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import ApplicationCard from "./components/ApplicationCard";
import TagOutlinedIcon from "@mui/icons-material/TagOutlined";
import AutoAwesomeMotionOutlinedIcon from "@mui/icons-material/AutoAwesomeMotionOutlined";
import BorderOuterOutlinedIcon from "@mui/icons-material/BorderOuterOutlined";
import ImageListing from "./components/ImageListing";
import Content from "./components/Content";
import { styled } from "@mui/material/styles";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const CampaignDetails = () => {
  const { campaignId } = useParams();
  const dispatch = useDispatch();
  const [showSkeleton, setShowSkeleton] = useState(true);
  const [selectedCardInfo, setSelectedCardInfo] = useState(null);
  const handleCardClick = (itemData) => {
    setSelectedCardInfo(itemData);
  };
  console.log(selectedCardInfo);
  useEffect(() => {
    dispatch(fetchCampaignDetails(campaignId));
    const timer = setTimeout(() => {
      setShowSkeleton(false);
    }, 2000);

    return () => {
      clearTimeout(timer);
    };
  }, [dispatch, campaignId]);

  const { data: campaignContent = [] } = useSelector(
    (state) => state?.getCampaignContent.data
  );

  console.log(campaignContent);

  const { data: campaign = {} } = useSelector(
    (state) => state.campaignDetails?.data
  );
  const languageArr = campaign?.campaign_languages?.map(
    (ele) => ele.language?.language_name
  );

  console.log(campaign)

  const campaignDetailsData = {
    id: campaign?.campaign_id,
    name: campaign?.campaign_name,
    location: campaign?.location,
    about: campaign?.campaign_about,
    brief: campaign?.about_product,
    startDate: new Date(campaign?.campaign_start_dt).toLocaleDateString(),
    endDate: new Date(campaign?.campaign_end_dt).toLocaleDateString(),
    contentUploadDate : new Date(campaign?.content_upload_date).toLocaleDateString(),
    campaignSubmissionDate : new Date(campaign?.content_submission_deadline).toLocaleDateString(),
    budget: campaign?.campaign_budget,
    niche: campaign?.campaign_content_niches?.map(
      (ele) => ele?.content_niche?.content_niche_name
    ),
    image: campaign?.campaign_images,
    language: languageArr,
    platform: campaign?.campaign_platforms,
    paymentType: campaign?.campaign_payment_type?.name,
    brandDetails: campaign?.brand,
    deliverables: campaign?.campaign_deliverable,
    contentNiche: campaign?.campaign_content_niches,
    campaignApplication: campaign?.campaign_applications,
  };

  console.log(campaignDetailsData);

  const { data: updateContentData } = useSelector(
    (state) => state.updateContentState.data
  );
  console.log(updateContentData);
  return (
    <>
      <Layout>
        <Box sx={{ position: "relative" }}>
          {showSkeleton ? (
            <Skeleton
              variant="rectangular"
              style={{
                width: "100%",
                height: "400px",
                objectFit: "cover",
                borderRadius: "20px",
              }}
            />
          ) : (
            <img
              src={campaignDetailsData?.image[0]?.image_link || null}
              alt="Banner"
              style={{
                width: "100%",
                height: "600px",
                objectFit: "fill",
                borderRadius: "20px",
              }}
            />
          )}
        </Box>
        <Box height={15} />

        <Grid container spacing={3}>
          <Grid item xs={12} md={3} lg={3} xl={3}>
            <Card
              elevation={1}
              style={{
                borderRadius: "20px",
                backgroundColor: "rgb(239, 242, 255)",
              }}
              gap={0}
            >
              <CardContent
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  flexDirection: "row",
                  borderRadius: "10px",
                  padding: "10px",
                }}
              >
                <Stack flexDirection="row" alignItems="center">
                  <Avatar
                    sx={{
                      backgroundColor: "transparent",
                    }}
                  >
                    <TagOutlinedIcon
                      sx={{
                        color: "#000",
                        fontSize: "30px",
                      }}
                    />
                  </Avatar>

                  <Typography variant="body">Post Needed</Typography>
                </Stack>
                <Typography>
                  {campaignDetailsData?.deliverables?.post}
                </Typography>
              </CardContent>

              <CardContent
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  flexDirection: "row",
                  borderRadius: "10px",
                  padding: "10px",
                }}
              >
                <Stack flexDirection="row" alignItems="center">
                  <Avatar
                    sx={{
                      backgroundColor: "transparent",
                    }}
                  >
                    <PlayCircleFilledWhiteOutlinedIcon
                      sx={{
                        color: "#000",
                        fontSize: "30px",
                      }}
                    />
                  </Avatar>

                  <Typography variant="body">Video Needed </Typography>
                </Stack>
                <Typography>
                  {campaignDetailsData?.deliverables?.youtube}
                </Typography>
              </CardContent>
              <CardContent
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  flexDirection: "row",
                  borderRadius: "10px",
                  padding: "10px",
                }}
              >
                <Stack flexDirection="row" alignItems="center">
                  <Avatar
                    sx={{
                      backgroundColor: "transparent",
                    }}
                  >
                    <AutoAwesomeMotionOutlinedIcon
                      sx={{
                        color: "#000",
                        fontSize: "30px",
                      }}
                    />
                  </Avatar>

                  <Typography variant="body">Reel Needed</Typography>
                </Stack>
                <Typography>
                  {campaignDetailsData?.deliverables?.reels}
                </Typography>
              </CardContent>
              <CardContent
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  flexDirection: "row",
                  borderRadius: "10px",
                  padding: "10px",
                }}
              >
                <Stack flexDirection="row" alignItems="center">
                  <Avatar
                    sx={{
                      backgroundColor: "transparent",
                    }}
                  >
                    <BorderOuterOutlinedIcon
                      sx={{
                        color: "#000",
                        fontSize: "30px",
                      }}
                    />
                  </Avatar>

                  <Typography variant="body">Story Needed</Typography>
                </Stack>
                <Typography>
                  {campaignDetailsData?.deliverables?.story}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={9} lg={9}>
            <Card elevation={0} style={{ borderRadius: "20px" }}>
              <CardContent>
                <Typography variant="h3">
                  {campaignDetailsData?.name}
                </Typography>
                <Stack flexDirection="row" gap={1} sx={{ marginY: "7px" }}>
                  {campaignDetailsData?.language?.map((ele) => (
                    <Chip key={ele} label={ele} />
                  ))}
                </Stack>
                <Typography variant="body1">
                  {campaignDetailsData?.about}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <Box height={15} />
        <Grid container spacing={3}>
          <Grid item xs={12} md={3} lg={3}>
            <Card
              elevation={1}
              style={{
                borderRadius: "20px",
                backgroundColor: "rgb(239, 242, 255)",
              }}
              gap={0}
            >
              {campaignDetailsData?.platform?.map((ele, index) => (
                <CardContent
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    flexDirection: "row",
                    borderRadius: "10px",
                    padding: "10px",
                  }}
                  key={index}
                >
                  <Stack flexDirection="row" alignItems="center">
                    <Avatar
                      sx={{
                        backgroundColor: "transparent",
                        width: 30,
                        height: 30,
                        marginRight: "7px",
                      }}
                      src={ele?.platform?.platform_img}
                    ></Avatar>

                    <Typography variant="body">
                      {ele?.platform?.platform_name}
                    </Typography>
                  </Stack>
                  <Typography>
                    {/* {ele?.platform?.platform_name} */}
                    20K
                  </Typography>
                </CardContent>
              ))}
            </Card>
          </Grid>
          <Grid item xs={12} md={9} lg={9}>
            {campaignDetailsData?.image ? (
              <ImageListing itemData={campaignDetailsData?.image} />
            ) : (
              <Skeleton variant="rect" height={200} />
            )}
            <Stack>
              <Typography variant="h4">Content Niche</Typography>
              <Stack
                flexDirection="row"
                sx={{
                  marginTop: "10px",
                }}
              >
                {campaignDetailsData.contentNiche &&
                  campaignDetailsData.contentNiche?.map((niche) => (
                    <Chip
                      key={
                        niche && niche.content_niche
                          ? niche.content_niche.campaign_content_niche_id
                          : undefined
                      }
                      label={
                        niche && niche.content_niche
                          ? niche.content_niche.content_niche_name
                          : undefined
                      }
                      variant="outlined"
                      sx={{ borderRadius: "10px", marginX: "5px" }}
                    />
                  ))}
              </Stack>
            </Stack>
          </Grid>
        </Grid>
        <Box height={15} />
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <Typography variant="h3" py={2}>
              Campaign Brief
            </Typography>
            <Typography variant="body1">
              {campaignDetailsData?.brief}
            </Typography>
          </Grid>
        </Grid>
        <Box height={10} />
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <Typography variant="h5" mb={2}>Some Important Dates</Typography>
            <Stack
              direction="row"
              divider={<Divider orientation="vertical" flexItem />}
              spacing={2}
            >
              <Item>{`Start Date : ${campaignDetailsData?.startDate}`}</Item>
              <Item>{`End Date : ${campaignDetailsData?.endDate}`}</Item>
              <Item>{`Content Submission Date : ${campaignDetailsData?.campaignSubmissionDate}`}</Item>
              <Item>{`Content End Date : ${campaignDetailsData?.contentUploadDate}`}</Item>
            </Stack>
          </Grid>
        </Grid>
        <Box height={10} />
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <ApplicationCard onCardClick={handleCardClick} />
          </Grid>
        </Grid>

        <Box height={15} />
        {campaignContent && campaignContent.length > 0 ? (
          <Content cardInfo={selectedCardInfo} />
        ) : (
          ""
        )}
      </Layout>
    </>
  );
};

export default CampaignDetails;
