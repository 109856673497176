import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { useNavigate } from "react-router-dom";
import { useAppStore } from "../../appStore";
import LogoutIcon from "@mui/icons-material/Logout";
import { useDispatch, useSelector } from "react-redux";
import { logout, clearState } from "../../features/auth/logOutSlice";
import { persistor } from "../../store";
import BarChartRoundedIcon from "@mui/icons-material/BarChartRounded";
import DashboardRoundedIcon from "@mui/icons-material/DashboardRounded";
import AddchartRoundedIcon from "@mui/icons-material/AddchartRounded";
import Person4RoundedIcon from "@mui/icons-material/Person4Rounded";
import FaceRoundedIcon from "@mui/icons-material/FaceRounded";
import Diversity1RoundedIcon from "@mui/icons-material/Diversity1Rounded";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  backgroundColor: "#f9faf9",
  padding: theme.spacing(0, 1),
  visibility: "hidden",
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  elevation: "20px",
  borderRadius: "30px",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const menuItems = [
  {
    text: "Home",
    icon: <DashboardRoundedIcon sx={{ color: "#71797E" }} />,
    path: "/",
  },
  {
    text: "My Campaigns",
    icon: <BarChartRoundedIcon sx={{ color: "#71797E" }} />,
    path: "/campaigns",
  },
  {
    text: "Create Campaigns",
    icon: <AddchartRoundedIcon sx={{ color: "#71797E" }} />,
    path: "/create-campaign",
  },
  {
    text: "Profile",
    icon: <Person4RoundedIcon sx={{ color: "#71797E" }} />,
    path: "/profile",
  },
  {
    text: "Influencers",
    icon: <FaceRoundedIcon sx={{ color: "#71797E" }} />,
    path: "/influencer",
  },
  {
    text: "Favourite Influencers",
    icon: <Diversity1RoundedIcon sx={{ color: "#71797E" }} />,
    path: "/fav-influencers",
  },
];

function SideNav() {
  const theme = useTheme();

  const open = useAppStore((state) => state.dopen);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(logout());
    dispatch(clearState());
    persistor.purge();
    navigate("/login");
  };

  const { data: brandData = {} } = useSelector(
    (state) => state.brandprofileget?.data
  );
  console.log(brandData);

  const profileStatus = brandData?.profile_status;
  console.log(profileStatus);

  return (
    <Box sx={{ display: "flex" }} elevation={5}>
      <CssBaseline />
      <Box height={50} />
      <Drawer
        variant="permanent"
        open={open}

        // PaperProps={{
        //   sx: {
        //     backgroundColor: "#F7EFE5",
        //     height: 'calc(100% - 175px)',
        //     top: 87.5,
        //     bottom : 87.5,
        //     right : 15,
        //     borderRadius : "20px",
        //     marginLeft : "10px",
        //   },
        // }}
      >
        <DrawerHeader>
          <IconButton>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <List sx={{ border: "none" }}>
            {menuItems.map((item, index) => (
              <ListItem
                key={index}
                disablePadding
                sx={{ display: "block", border: "none" }}
                {...(profileStatus === 0 && item.text !== "Home"
                  ? { disabled: true }
                  : {})}
                onClick={() => {
                  if (
                    (profileStatus === 0 && item.text === "Home") ||
                    profileStatus === 1
                  ) {
                    navigate(item.path);
                  }
                }}
              >
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",

                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText
                    primary={item.text}
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </List>

          <List sx={{ border: "none" }}>
            <ListItem disablePadding sx={{ display: "block" }}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
                onClick={handleLogout}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <LogoutIcon sx={{ color: "#71797E" }} />
                </ListItemIcon>
                <ListItemText
                  primary="Log Out"
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </ListItem>
          </List>
        </Box>
      </Drawer>
    </Box>
  );
}

export { SideNav };
