import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios'; 

const initialState = {
  data: [],
  loading: false,
  error: null,
};

export const fetchProfile = createAsyncThunk(
  'brandprofile/fetchData',
  async (_, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const token = state.otp.data.token;
      const brandId = state.otp.data.brands_id;
      console.log(brandId);

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      
      const response = await axios.get(
        `https://api.republicofinfluencers.in/v2/brands/profile/details/${brandId}`,
        config
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const getProfileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchProfile.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchProfile.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchProfile.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default getProfileSlice.reducer;
