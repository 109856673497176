import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  data: [],
  loading: false,
  error: null,
};

export const broadcastComment = createAsyncThunk(
  "comments/broadCast",
  async ({ inflenecrArr, comment}, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const token = state.otp.data.token;
      const brandId = state.otp.data.brands_id;
      console.log(inflenecrArr,comment)
      const data = {
        campaign_broadcast: inflenecrArr?.map((ele) => ({
          campaign_applied_id: ele?.campaignAppId,
          influencer_id: ele?.influencerId,
          brand_id: brandId,
          sender_type: "BRANDS",
          comment_text: comment,
          comment_file: [""],
          file_type: "image",
        })),
      };
      console.log(data)
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-type": "application/json",
        },
      };
      const response = await axios.post(
        `https://api.republicofinfluencers.in/v2/brands/campaign_broadcast/add/${brandId}`,
        data,
        config
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const broadcastCommentSlice = createSlice({
  name: "broadcastComment",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(broadcastComment.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(broadcastComment.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(broadcastComment.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default broadcastCommentSlice.reducer;
