import { combineReducers } from "redux";
import authReducer from "../features/auth/authSlice";
import otpVerifySlice from "../features/auth/otpVerifySlice";
import campaignSlice from "../features/campaign/getCampagin";
import editcampaignSlice from "../features/campaign/editCampaign";
import createcampaignSlice from "../features/campaign/createCampaign";
import getProfileSlice from "../features/brandprofile/getBrandProfile";
import getContentNicheSlice from "../features/resources/getContentNiche";
import getStateSlice from "../features/resources/getState";
import getCitySlice from "../features/resources/getCity";
import getBrandTypeSlice from "../features/resources/getBrandType";
import getBuisnessListSlice from "../features/resources/getBuissnessList";
import deletecampaignSlice from "../features/campaign/deleteCampaign";
import editBrandProfileSlice from "../features/brandprofile/editBrandProfile";
import getDemoInfluencersSlice from "../features/influencers/demoInfulencers";
import getInfluencersListSlice from "../features/influencers/getInfluencerList";
import getInfluencersDetailsSlice from "../features/influencers/getInfluencerDetails";
import logout from "../features/auth/logOutSlice";
import visibilityReducer from "../features/completeprofile/visibilitySlice";
import campaignDetailsSlice from "../features/campaign/campaignDetails";
import makeFavourite from "../features/influencers/makeFavourite";
import getPaymentTypeSlice from "../features/resources/getPaymentType";
import getCampaignGoalSlice from "../features/resources/getCampaignGoal";
import getPlatformSlice from "../features/resources/getPlatform";
import getFavouriteSlice from "../features/influencers/getFavourite";
import campaignApplicationsReducer from "../features/campaignapplication/campaignApplicationsSlice";
import get from "../features/comments/get";
import add from "../features/comments/add";
import languageSlice from "../features/resources/getLanguage";
import deleteComments from "../features/comments/deleteComments";
import favoriteReducer from "../features/favoriteSlice";
import platformDelete from "../features/resources/platformDelete";
import contentNicheDelete from "../features/resources/contentNicheDelete";
import deleteCampaignImageSlice from "../features/campaign/deleteCampaignImage";
import campaignImageSlice from "../features/campaign/getCampaignImage";
import broadcastComment from "../features/comments/broadCast";
import fetchCampaignContent from "../features/campaignContent/getCampaignContent";
import updateCampaignContent from "../features/campaignContent/updateCampaignContent";
import getInfluencerTypeData from "../features/resources/getInfluencerType";
import getAgeOfAudience from "../features/resources/getAge";
import getGender from "../features/resources/getGender";

const rootReducer = combineReducers({
  auth: authReducer,
  otp: otpVerifySlice,
  logout: logout,
  brandprofileget: getProfileSlice,
  campaign: campaignSlice,
  campaignDetails: campaignDetailsSlice,
  editcampaign: editcampaignSlice,
  createCampaign: createcampaignSlice,
  deleteCampaign: deletecampaignSlice,
  deleteCampaignImage: deleteCampaignImageSlice,
  editBrandProfile: editBrandProfileSlice,
  contentNiche: getContentNicheSlice,
  stateData: getStateSlice,
  cityData: getCitySlice,
  languageData: languageSlice,
  brandType: getBrandTypeSlice,
  buissnessList: getBuisnessListSlice,
  paymentType: getPaymentTypeSlice,
  campaignGoal: getCampaignGoalSlice,
  platform: getPlatformSlice,
  platformDelete: platformDelete,
  demoInfluencers: getDemoInfluencersSlice,
  influencersList: getInfluencersListSlice,
  influencerDetails: getInfluencersDetailsSlice,
  makeFavInfluencer: makeFavourite,
  getFavInfluencer: getFavouriteSlice,
  visibility: visibilityReducer,
  campaignApplications: campaignApplicationsReducer,
  getComments: get,
  addComments: add,
  deleteComments: deleteComments,
  favorite: favoriteReducer,
  nicheData: contentNicheDelete,
  getCampaignImage: campaignImageSlice,
  broadCast: broadcastComment,
  getCampaignContent: fetchCampaignContent,
  influencerType: getInfluencerTypeData,
  updateContentState: updateCampaignContent,
  getAgeOfAudience: getAgeOfAudience,
  getGender: getGender,
});

export default rootReducer;
