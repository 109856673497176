import {create} from "zustand";
import { persist } from "zustand/middleware";

let appStore = (set) => ({
  dopen: true,
  isProfileCompleted: false,
  updateOpen: (dopen) => set((state) => ({ dopen: dopen })),
  setProfileCompleted: (completed) => set((state) => ({ isProfileCompleted: completed })),
});


appStore = persist(appStore, {name: "my_app_store" });
export const useAppStore = create(appStore);