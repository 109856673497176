/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import { Layout } from "../../components";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { fetchState } from "../../features/resources/getState";
import { fetchCity } from "../../features/resources/getCity";
import { fetchBrandType } from "../../features/resources/getBrandType";
import { fetchBuisnessList } from "../../features/resources/getBuissnessList";
import { editBrand } from "../../features/brandprofile/editBrandProfile";
import { Autocomplete, Button, Grid, MenuItem, TextField } from "@mui/material";

const CompleteProfile = () => {
  const { register, handleSubmit, setValue, control } = useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [stateIdMatch, setStateIdMatch] = useState(0);
  const [industryType, setIndustryType] = useState([]);
  useEffect(() => {
    dispatch(fetchState());
    dispatch(fetchCity(stateIdMatch));
    dispatch(fetchBrandType());
    dispatch(fetchBuisnessList());
  }, [stateIdMatch, dispatch]);

  const { data: StateDataPull = [] } = useSelector(
    (state) => state.stateData?.data
  );
  console.log(StateDataPull);

  const { data: cityData = [] } = useSelector((state) => state.cityData?.data);
  console.log(cityData);

  const stateId = StateDataPull?.map((stateObj) => stateObj.state_id);
  const { data: brandData = [] } = useSelector(
    (state) => state.brandType?.data
  );
  console.log(brandData);
  const { data: buisenessListData = [] } = useSelector(
    (state) => state.buissnessList?.data
  );
  console.log(buisenessListData);
  const handleChange = (e) => {
    const index = e.target.value;
    stateId.filter(() => {
      if (Number(index)) {
        setStateIdMatch(index);
      } else {
        console.log("not Matched");
      }
    });
  };
  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const base64Data = event.target.result.split(",")[1];
        setValue("logo", base64Data);
      };
      reader.readAsDataURL(file);
    }
  };

  const { data: profileData } = useSelector(
    (state) => state.brandprofileget.data
  );

  const onSubmit = async (data) => {
    try {
      const indusValue = industryType.map((el) => el.value);

      const formattedData = {
        brand_logo: data.logo,
        brand_type_id: parseInt(data.brandType),
        name: data.name,
        email: data.email,
        number: profileData?.number,
        pan_card: data.panCard,
        gst_number: data.gstNumber,
        website: data.website,
        address: data.address,
        overview: data.overview,
        city_id: parseInt(data.City),
        state_id: parseInt(data.State),
        industry_ids: indusValue.map(Number),
        facebook_url: data.facebook,
        instagram_url: data.instagram,
        youtube_url: data.youtube,
      };
      console.log(formattedData);
      await dispatch(editBrand(formattedData));
      // dispatch(hideButton());
      navigate("/");
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  return (
    <>
      <Layout>
        <div className="container">
          <h4>Complete Your Profile</h4>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2} mt={3}>
              <Grid item xs={12} md={6} mb={3}>
                <TextField
                  label="Name"
                  id="name"
                  variant="outlined"
                  fullWidth
                  placeholder="Name"
                  {...register("name", { required: true })}
                />
              </Grid>
              <Grid item xs={12} md={6} mb={3}>
                <TextField
                  fullWidth
                  type="file"
                  label="Logo"
                  id="logo"
                  placeholder="Logo"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => handleImageUpload(e)}
                />
              </Grid>
              <Grid item xs={12} md={6} mb={3}>
                <TextField
                  fullWidth
                  id="email"
                  label="Email"
                  {...register("email", {
                    required: true,
                    pattern: /^\S+@\S+$/i,
                  })}
                />
              </Grid>
              <Grid item xs={12} md={6} mb={3}>
                <TextField
                  type="tel"
                  id="phoneNumber"
                  label="Phone Number"
                  variant="outlined"
                  fullWidth
                  defaultValue={profileData?.number}
                  disabled
                  placeholder="Phone Number"
                  // {...register("phoneNumber", { required: true })}
                />
              </Grid>
              <Grid item xs={12} md={6} mb={3}>
                <TextField
                  id="panCard"
                  label="Pan Card"
                  variant="outlined"
                  fullWidth
                  {...register("panCard", { required: true })}
                />
              </Grid>
              <Grid item xs={12} md={6} mb={3}>
                <TextField
                  id="gstNumber"
                  fullWidth
                  label="Gst Number"
                  variant="outlined"
                  {...register("gstNumber", { required: true })}
                />
              </Grid>
              <Grid item xs={12} md={6} mb={3}>
                <TextField
                  id="website"
                  label="Website"
                  fullWidth
                  variant="outlined"
                  {...register("website", {
                    required: true,
                    pattern:
                      "(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?",
                  })}
                />
              </Grid>
              <Grid item xs={12} md={6} mb={3}>
                <TextField
                  id="facebook"
                  type="url"
                  label="Facebook"
                  variant="outlined"
                  fullWidth
                  {...register("facebook", { required: true })}
                />
              </Grid>
              <Grid item xs={12} md={6} mb={3}>
                <TextField
                  id="instagram"
                  label="Instagram"
                  variant="outlined"
                  fullWidth
                  {...register("instagram", { required: true })}
                />
              </Grid>
              <Grid item xs={12} md={6} mb={3}>
                <TextField
                  id="youtube"
                  label="Youtube"
                  variant="outlined"
                  fullWidth
                  {...register("youtube", { required: true })}
                />
              </Grid>
              <Grid item xs={12} md={6} mb={3}>
                <TextField
                  id="address"
                  label="Address"
                  variant="outlined"
                  fullWidth
                  {...register("address", { required: true })}
                />
              </Grid>
              <Grid item xs={12} md={6} mb={3}>
                <TextField
                  id="overview"
                  label="Overview"
                  variant="outlined"
                  fullWidth
                  {...register("overview", { required: true })}
                />
              </Grid>
              <Grid item xs={12} md={6} mb={3}>
                <TextField
                  select
                  variant="outlined"
                  fullWidth
                  label="State"
                  id="state"
                  {...register("State", { required: true })}
                  onChange={handleChange}
                >
                  <MenuItem value="">Select State</MenuItem>
                  {StateDataPull.map((stateObj, index) => (
                    <MenuItem key={stateObj.state_id} value={stateObj.state_id}>
                      {stateObj.state_name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} md={6} mb={3}>
                <TextField
                  select
                  id="city"
                  label="City"
                  variant="outlined"
                  fullWidth
                  {...register("City")}
                >
                  <MenuItem value="">Select City</MenuItem>
                  {cityData.map((cityObj) => (
                    <MenuItem key={cityObj.city_id} value={cityObj.city_id}>
                      {cityObj.city_name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} md={6} mb={3}>
                <Controller
                  name="industryType"
                  control={control}
                  render={({ field }) => (
                    <Autocomplete
                      multiple
                      id="industryType"
                      options={buisenessListData.map((buissnessObj) => ({
                        value: buissnessObj.industry_id,
                        label: buissnessObj.industry_name,
                      }))}
                      value={industryType}
                      onChange={(_, selectedOptions) => {
                        setIndustryType(selectedOptions);
                        field.onChange(selectedOptions);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          placeholder="Select Industry Type(s)"
                        />
                      )}
                      getOptionDisabled={(option) => {
                        industryType.some(
                          (selectedOptions) =>
                            selectedOptions.value === option.value
                        );
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6} mb={3}>
                <TextField
                  select
                  id="brandType"
                  label="Brand Type"
                  variant="outlined"
                  fullWidth
                  {...register("brandType")}
                >
                  <MenuItem value="">Select the Type of Brand</MenuItem>
                  {brandData.map((brandObj, index) => (
                    <MenuItem key={index} value={brandObj.brand_type_id}>
                      {brandObj.brand_type_name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>
            <Button
              type="submit"
              sx={{
                borderRadius: "10px",
                backgroundColor: "#000",
                color: "#fff",
                textTransform: "none",
                "&:hover": {
                  backgroundColor: "#fff",
                  color: "#000",
                },
              }}
            >
              Submit
            </Button>
          </form>
        </div>
      </Layout>
    </>
  );
};

export default CompleteProfile;
