import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";

const SocialCard = ({ socialMediaData }) => {
  return (
    <Grid container spacing={2} style={{ marginTop: "5px" }}>
      {socialMediaData.map((socialMedia, index) => (
        <Grid key={index} item xs={12} sm={6} md={4} lg={3}>
          <Card variant="outlined" sx={{ borderRadius: "10px" }}>
            <CardContent>
              <Grid container alignItems="center">
              <Grid item xs={6} >
                  {socialMedia.icon === "facebook" && (
                    <Typography variant="h3" color="primary">
                      <FacebookIcon
                        style={{ fontSize: "50px", color: "#1976d2" }}
                      />
                    </Typography>
                  )}
                  {socialMedia.icon === "instagram" && (
                    <Typography variant="h3" color="primary">
                      <InstagramIcon
                        style={{ fontSize: "50px", color: "#fede7f" }}
                      />
                    </Typography>
                  )}
                  {socialMedia.icon === "youtube" && (
                    <Typography variant="h3" color="primary">
                      <YouTubeIcon
                        style={{ fontSize: "50px", color: "#ff0000" }}
                      />
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    sx={{ whiteSpace: "normal", wordWrap: "break-word" }}
                  >
                    {socialMedia.url}
                  </Typography>
                </Grid>
                
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default SocialCard;
